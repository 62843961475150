import { EAlertItemType } from './cls-alerts.enums';

/**
 * @class
 * Class representing an alert.
 */
export class ClsAlert {
	/**
	 * @type {string}
	 * The title of the alert.
	 */
	title: string = '';

	/**
	 * @type {string}
	 * The description of the alert.
	 */
	description: string = '';

	/**
	 * @type {EAlertItemType}
	 * The type of the alert.
	 */
	type: EAlertItemType = EAlertItemType.Primary;

	/**
	 * @type {Date}
	 * The creation date of the alert.
	 */
	creationDate: Date;

	/**
	 * @type {number | undefined}
	 * The duration of the alert in milliseconds - optional.
	 */
	duration?: number;

	/**
	 * @type {string}
	 * displayed action text
	 */
	actionText?: string = '';

	/**
	 * @type {Function}
	 * An action function to trigger from the alert.
	 */
	action?: () => any;

	/**
	 * @constructor
	 * @param {string} title - The alert title.
	 * @param {string} description - The alert description.
	 * @param {EAlertItemType} type - The alert type.
	 * @param {number} duration - The alert duration in milliseconds.
	 */
	constructor(
		title: string,
		description: string,
		type: EAlertItemType,
		duration: number | undefined = undefined,
		actionText?: string,
		action?: () => any
	) {
		this.title = title;
		this.description = description;
		this.type = type;
		this.creationDate = new Date();
		this.duration = duration;
		this.actionText = actionText;
		this.action = action;
	}
}
