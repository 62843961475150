export enum ECookiesKeys {
	RefId = 'refid',
	ComparedItems = 'ComparedItems',
	UserAnonymouseCompareCount = 'cl_cc',
	UserAnonymousId = 'cl_uaid',
	ActivatedUser = 'user_activated',
	Langauge = 'language',
	ISession = 'isession',
	IdentityAIA = 'aia',
	FPromTidId = '_fprom_tid',
	FPromRefId = '_fprom_ref',
	AwinTidId = '_aw_awc',
	AwinRefId = '_aw_awc',
	CRef = 'cRef',
}
