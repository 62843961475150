import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { EAlertItemType } from '../models/cls-alerts.enums';
import { ClsAlert } from '../models/cls-alerts.models';
import { HttpErrorResponse } from '@angular/common/http';
import { LogsService } from '@ngx-common/services/logs.service';

/**
 * Service that manages the creation, deletion, and updating of alerts.
 * It can also be used to change the position of the alert container
 */
@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class ClsAlertsService {
	/**
	 * The subscription reference to the alerts life time checker interval
	 */
	private _alertLifeTimeCheckSub$: undefined | Subscription;

	//#region alerts related subject, setters and getters
	private _alerts$: BehaviorSubject<ClsAlert[]> = new BehaviorSubject<ClsAlert[]>([]);
	public get alerts$() {
		return this._alerts$;
	}
	public set alerts(newAlerts: ClsAlert[]) {
		this._alerts$.next(newAlerts);
	}
	public get alerts(): ClsAlert[] {
		return this._alerts$.value;
	}
	//#endregion

	constructor(private _logSvc: LogsService) {}

	/**
	 * Adds a new primary alert, and shows its on the top right of the screen
	 * @param title The title of the alert
	 * @param description The description of the alert
	 * @param duration The duration time of the alert in milliseconds
	 * @public
	 */
	public showPrimary(title: string, description: string, duration: number | undefined = undefined) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		let alert = new ClsAlert(title, description, EAlertItemType.Primary, duration);
		if (!this._checkIfAlertAlreadyExists(title, description, EAlertItemType.Primary)) {
			this._alerts$.next([alert, ...this.alerts]);
		}
		return alert;
	}

	/**
	 * Adds a new primary alert, and shows its on the top right of the screen
	 * @param title The title of the alert
	 * @param description The description of the alert
	 * @param duration The duration time of the alert in milliseconds
	 * @public
	 */
	public showCustomPrimary(description: string, duration?: number) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		let alert = new ClsAlert('', description, EAlertItemType.Primary, duration ? duration : 6000);
		if (!this._checkIfAlertAlreadyExists('', description, EAlertItemType.Primary)) {
			this._alerts$.next([alert, ...this.alerts]);
		}
		return alert;
	}

	/**
	 * Adds a new warning alert, and shows its on the top right of the screen
	 * @param title The title of the alert
	 * @param description The description of the alert
	 * @param duration The duration time of the alert in milliseconds
	 * @public
	 */
	public showWarning(title: string, description: string, duration: number | undefined = undefined) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		let alert = new ClsAlert(title, description, EAlertItemType.Warning, duration);
		if (!this._checkIfAlertAlreadyExists(title, description, EAlertItemType.Warning)) {
			this._alerts$.next([alert, ...this.alerts]);
		}
		return alert;
	}

	/**
	 * Adds a new warning alert (no title and default duration), and shows its on the top right of the screen
	 * @param title The title of the alert
	 * @param description The description of the alert
	 * @param duration The duration time of the alert in milliseconds
	 * @public
	 */
	public showCustomWarning(description: string, duration?: number) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		let alert = new ClsAlert('', description, EAlertItemType.Warning, duration ? duration : 6000);
		if (!this._checkIfAlertAlreadyExists('', description, EAlertItemType.Warning)) {
			this._alerts$.next([alert, ...this.alerts]);
		}
		return alert;
	}

	/**
	 * Adds a new error alert, and shows its on the top right of the screen
	 * @param title The title of the alert
	 * @param description The description of the alert
	 * @param duration The duration time of the alert in milliseconds
	 * @public
	 */
	public showError(title: string, description: string, duration: number | undefined = undefined) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		let alert = new ClsAlert(title, description, EAlertItemType.Error, duration);
		if (!this._checkIfAlertAlreadyExists(title, description, EAlertItemType.Error)) {
			this._alerts$.next([alert, ...this.alerts]);
		}
		return alert;
	}

	/**
	 * Adds a new error alert (no title and default duration), and shows its on the top right of the screen
	 * @param description The description of the alert
	 * @public
	 */
	public showCustomError(description: string, duration?: number) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		let alert = new ClsAlert('', description, EAlertItemType.Error, duration ? duration : 6000);
		if (!this._checkIfAlertAlreadyExists('', description, EAlertItemType.Error)) {
			this._alerts$.next([alert, ...this.alerts]);
		}
		return alert;
	}

	/**
	 * Adds a new http error response alert, and shows its on the top right of the screen
	 * @param err The http response error returned from the server
	 * @public
	 */
	public showHttpResponseError(err: HttpErrorResponse) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();

		if (err?.error?.description) {
			if (!this._checkIfAlertAlreadyExists('', err.error.description, EAlertItemType.Error)) {
				this._alerts$.next([new ClsAlert('', err.error.description, EAlertItemType.Error, 15000), ...this.alerts]);
			}
			return;
		}

		if (err?.status === 401) {
			return;
		}

		if (
			!this._checkIfAlertAlreadyExists(
				$localize`Unknown Error:`,
				$localize`Please try again in a few seconds. If the error persists, please contact support@copyleaks.com for more help.`,
				EAlertItemType.Error
			)
		) {
			this._alerts$.next([
				new ClsAlert(
					$localize`Unknown Error:`,
					$localize`Please try again in a few seconds. If the error persists, please contact support@copyleaks.com for more help.`,
					EAlertItemType.Error,
					15000
				),
				...this.alerts,
			]);
		}

		const errorString = JSON.stringify(err);
		this._logSvc.logErrorToServer(`$showHttpResponseError - ${errorString}`);
	}

	/**
	 * Adds a new global support error alert, that shows the on the top right of the screen
	 * @public
	 */
	public showSupportError() {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		this._alerts$.next([
			new ClsAlert(
				$localize`Unknown Error`,
				$localize`Please try again in a few seconds. If the error happens again, please contact the support.`,
				EAlertItemType.Error,
				15000
			),
			...this.alerts,
		]);
	}

	/**
	 * Adds a vesion update alert, and shows its on the top right of the screen
	 * @param title The title of the alert
	 * @param description The description of the alert
	 * @param duration The duration time of the alert in milliseconds
	 * @public
	 */
	public showVersionUpdateMessage(action?: () => any) {
		if (this._alerts$.value.length === 0) this._startAlertLifeTimeChecker();
		let alert = new ClsAlert(
			$localize`A new version of Copyleaks is available`,
			$localize`Refresh to get the latest version.`,
			EAlertItemType.Refresh,
			1800000,
			'Refresh',
			action
		);
		if (
			!this._checkIfAlertAlreadyExists(
				$localize`A new version of Copyleaks is available`,
				$localize`Refresh to get the latest version.`,
				EAlertItemType.Refresh
			)
		) {
			this._alerts$.next([alert, ...this.alerts]);
		}
		return alert;
	}

	/**
	 * Remove the given alert from the alert list (delete by reference)
	 * @param alert The to be removed alert object
	 */
	public remove(alert: ClsAlert) {
		const updatedAlerts = this.alerts.filter(a => a != alert);
		this._alerts$.next(updatedAlerts);
		// unsubscribe from the interval timer for alerts if there is no alerts
		if (updatedAlerts.length === 0) this._alertLifeTimeCheckSub$?.unsubscribe();
	}

	/**
	 * @private
	 * Start an interval timer that fires every 100 milliseconds and removes all alerts that have expired
	 */
	private _startAlertLifeTimeChecker() {
		this._alertLifeTimeCheckSub$ = interval(100)
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				var timeNow = Date.now();

				// filter out alerts that haven't expired or have no expiration
				const filteredAlerts = this.alerts.filter(a => !a.duration || a.creationDate.getTime() + a.duration > timeNow);
				this._alerts$.next(filteredAlerts);

				// unsubscribe from the interval timer for alerts if there is no alerts
				if (filteredAlerts.length === 0) this._alertLifeTimeCheckSub$?.unsubscribe();
			});
	}

	private _checkIfAlertAlreadyExists(title: string, description: string, type: EAlertItemType) {
		if (this.alerts.find(alert => alert.type == type && alert.description == description && alert.title == title)) {
			return true;
		}
		return false;
	}
}
