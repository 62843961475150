import { environment } from '@environment';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { isBrowserSupported } from './browser-support.utils';

export const SENTRY_ALLOWED_DOMAINS = [
	'copyleaks.com',
	'app.copyleaks.com',
	'api.copyleaks.com',
	'lti.copyleaks.com',
	'admin.copyleaks.com',
	'id.copyleaks.com',
];

export function CanCommunicateWithSentry() {
	const domain = window?.location?.host;
	return environment.production && SENTRY_ALLOWED_DOMAINS?.includes ? SENTRY_ALLOWED_DOMAINS.includes(domain) : false;
}

export function InitSentryForCopyleaks() {
	const userAgent = window?.navigator?.userAgent;
	const domain = window?.location?.host;
	if (isBrowserSupported(userAgent) && CanCommunicateWithSentry()) {
		const isDevelopment = domain.includes('localhost');
		const isStaging = domain.includes('beta');
		const isProduction = !isDevelopment && !isStaging;
		const appEnvironment = isProduction ? 'production' : isStaging ? 'staging' : 'development';
		Sentry.init({
			release: `${domain}@${environment.version}-${appEnvironment}`,
			environment: appEnvironment,
			dsn: 'https://eea45830378d417cad30281e61b9facf@o983579.ingest.sentry.io/5939363',
			integrations: [
				new Integrations.BrowserTracing({
					tracingOrigins: ['localhost', `https://${domain}/api`],
					routingInstrumentation: Sentry.routingInstrumentation,
				}),
			],
			tracesSampleRate: 0,
		});
	}
}
