import { Injectable, Inject, PLATFORM_ID, LOCALE_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { CLSTranslateService } from './translate.service';
import { NgxCommonPagesConfig } from '../pages/pages.config';
import { environment } from '@environment';

@Injectable({
	providedIn: 'root',
})
export class SEOService {
	environment = environment;
	constructor(
		private _titleSvc: Title,
		private _metaTagService: Meta,
		@Inject(DOCUMENT) private _document,
		@Inject(PLATFORM_ID) private platformId,
		@Inject(LOCALE_ID) private localeId: string,
		@Inject(NgxCommonPagesConfig.key) private config: NgxCommonPagesConfig,
		private _translateSvc: CLSTranslateService
	) {}

	addBreadcrumb(breadcrumbList: BreadcrumbItem[]) {
		const script: HTMLScriptElement = this._document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.text = `{
			"@context":"https://schema.org/",
			"@type":"BreadcrumbList",
			"itemListElement": [${breadcrumbList.map(
				(b, idx) =>
					`{
				"@type": "ListItem",
				"position": ${idx + 1},
				"name": "${b.name}",
				"item": "${b.url}"
			}`
			)}]
	 }`;
		this._document.body.appendChild(script);
	}

	async setPageTitle(title: string, ignoreTranslate = false) {
		let _title = title;

		if (!ignoreTranslate) {
			try {
				_title = await this._translateSvc.get(title).toPromise();
			} catch (error) {
				_title = title;
			}
		}

		this._titleSvc.setTitle(`${_title}`);
		this._metaTagService.updateTag({ name: 'og:title', content: _title });
		this._metaTagService.updateTag({ name: 'twitter:title', content: _title });
	}

	getPageTitle() {
		return this._titleSvc.getTitle();
	}

	async createMetaTagForDescription(description: string) {
		this._metaTagService.updateTag({
			name: 'description',
			content: description,
		});
		this._metaTagService.updateTag({
			name: 'og:description',
			content: description,
		});
		this._metaTagService.updateTag({
			name: 'twitter:description',
			content: description,
		});
	}

	async createMetaTagForKeywords(keywords: string) {
		this._metaTagService.updateTag({ name: 'keywords', content: keywords });
	}

	createMetaTagForImageUrl(imageUrl: string) {
		this._metaTagService.updateTag({
			name: 'og:image:source_url',
			content: imageUrl,
		});
		this._metaTagService.updateTag({
			name: 'twitter:image',
			content: imageUrl,
		});
	}

	createLinkForCanonicalURL(url: string, translation = true) {
		if (!this.config.IS_SSR_PROJECT || isPlatformServer(this.platformId)) {
			const existsCanonicalLinkTag = this._document.querySelector(`link[rel="canonical"]`);
			const baseUrl = `${url.substring(0, 21)}`;
			const pageUrl = `${url.substring(22)}`;

			url = this._canonicalOverraidUrls(url);

			let urlWithLocaleId: string;
			if (translation) {
				urlWithLocaleId = `${baseUrl}/${this.localeId}/${pageUrl}`;
			} else {
				urlWithLocaleId = `${baseUrl}/${pageUrl}`;
			}

			if (existsCanonicalLinkTag) {
				if (this.localeId === `en` || this.localeId === 'en-US') {
					existsCanonicalLinkTag.setAttribute('href', url);
				} else {
					existsCanonicalLinkTag.setAttribute('href', urlWithLocaleId);
				}
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'canonical');
				this._document.head.appendChild(link);
				if (this.localeId === `en` || this.localeId === 'en-US') {
					link.setAttribute('href', url);
					this._metaTagService.updateTag({ name: 'og:url', content: url });
				} else {
					link.setAttribute('href', urlWithLocaleId);
					this._metaTagService.updateTag({
						name: 'og:url',
						content: urlWithLocaleId,
					});
				}
			}
		}
	}

	private _canonicalOverraidUrls(url) {
		if (url == 'https://api.copyleaks.com' || url == 'https://api.copyleaks.com/') {
			return 'https://copyleaks.com/api';
		}

		if (url == 'https://app.copyleaks.com/v1/scan' || url == 'https://app.copyleaks.com/v1/scan/embedded') {
			return 'https://copyleaks.com/plagiarism-checker';
		}

		return url;
	}

	createLinkForExplorePages(url: string) {
		if (isPlatformServer(this.platformId)) {
			const existsCanonicalLinkTag = this._document.querySelector(`link[rel="canonical"]`);
			const baseUrl = `${url.substring(0, 21)}`;
			const params = url.split('/');
			const feature: string = params[params.length - 1];
			const urlWithLocaleId = `${baseUrl}/${this.localeId}/explore/all/show-all/${feature}`;
			if (existsCanonicalLinkTag) {
				if (this.localeId === `en` || this.localeId === 'en-US') {
					existsCanonicalLinkTag.setAttribute('href', url);
				} else {
					existsCanonicalLinkTag.setAttribute('href', urlWithLocaleId);
				}
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'canonical');
				this._document.head.appendChild(link);
				if (this.localeId === `en` || this.localeId === 'en-US') {
					link.setAttribute('href', url);
					this._metaTagService.updateTag({ name: 'og:url', content: url });
				} else {
					link.setAttribute('href', urlWithLocaleId);
					this._metaTagService.updateTag({
						name: 'og:url',
						content: urlWithLocaleId,
					});
				}
			}
		}
	}

	public createLinkForNoFollowUrl(): void {
		if (isPlatformServer(this.platformId) && this.localeId !== `en`) {
			const metaNoContent = this._document.querySelector(`meta[content="none"]`);
			if (!metaNoContent) {
				const meta: HTMLLinkElement = this._document.createElement('meta');
				meta.setAttribute('name', 'robots');
				meta.setAttribute('content', 'none');
				this._document.head.appendChild(meta);
			}
		}
	}

	public createLinkForHrefLang(url): void {
		if (isPlatformServer(this.platformId)) {
			const baseUrl = `${url.substring(0, 21)}`;
			const pageUrl = `${url.substring(22)}`;
			const existsXDefaultTag = this._document.querySelector(`link[hreflang="x-default"]`);
			if (existsXDefaultTag) {
				existsXDefaultTag.setAttribute('href', url);
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', 'x-default');
				this._document.head.appendChild(link);
				link.setAttribute('href', url);
			}

			const existsLanguageTagEs = this._document.querySelector(`link[hreflang="es"]`);
			if (existsLanguageTagEs) {
				existsLanguageTagEs.setAttribute('href', `${baseUrl}/es/${pageUrl}`);
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', `es`);
				const urlWithLocaleId = `${baseUrl}/es/${pageUrl}`;
				link.setAttribute('href', urlWithLocaleId);
				this._document.head.appendChild(link);
			}

			const existsLanguageTagFr = this._document.querySelector(`link[hreflang="fr"]`);
			if (existsLanguageTagFr) {
				existsLanguageTagFr.setAttribute('href', `${baseUrl}/fr/${pageUrl}`);
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', `fr`);
				const urlWithLocaleId = `${baseUrl}/fr/${pageUrl}`;
				this._document.head.appendChild(link);
				link.setAttribute('href', urlWithLocaleId);
			}

			const existsLanguageTagPt = this._document.querySelector(`link[hreflang="pt"]`);
			if (existsLanguageTagPt) {
				existsLanguageTagPt.setAttribute('href', `${baseUrl}/pt/${pageUrl}`);
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', `pt`);
				this._document.head.appendChild(link);
				link.setAttribute('href', `${baseUrl}/pt/${pageUrl}`);
			}

			const existsLanguageTagHi = this._document.querySelector(`link[hreflang="hi"]`);
			if (existsLanguageTagHi) {
				existsLanguageTagHi.setAttribute('href', `${baseUrl}/hi/${pageUrl}`);
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', `hi`);
				this._document.head.appendChild(link);
				link.setAttribute('href', `${baseUrl}/hi/${pageUrl}`);
			}

			const existsLanguageTagZh = this._document.querySelector(`link[hreflang="zh"]`);
			if (existsLanguageTagZh) {
				existsLanguageTagZh.setAttribute('href', `${baseUrl}/zh/${pageUrl}`);
			} else {
				const link: HTMLLinkElement = this._document.createElement('link');
				link.setAttribute('rel', 'alternate');
				link.setAttribute('hreflang', `zh`);
				this._document.head.appendChild(link);
				link.setAttribute('href', `${baseUrl}/zh/${pageUrl}`);
			}
		}
	}

	disableRobots() {
		const existsTag = this._document.querySelector(`META[NAME="ROBOTS"]`);

		if (!existsTag) {
			const link: HTMLLinkElement = this._document.createElement('META');
			link.setAttribute('NAME', 'ROBOTS');
			link.setAttribute('CONTENT', 'NOINDEX, NOFOLLOW');
			this._document.head.appendChild(link);
		}
	}

	addOrganization() {
		const script: HTMLScriptElement = this._document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.text = `{
			"@context": "https://schema.org",
			"@type": "Organization",
			"name": "Copyleaks",
			"url": "${this.config.APP_ORIGIN}/",
			"logo": "${environment.apiFrontEndUrl}/images/logo.png",
			"sameAs": [
			  "https://www.facebook.com/Copyleaks/",
			  "https://www.linkedin.com/company/copyleaks/",
			  "https://twitter.com/Copyleaks"
			]
		}`;
		this._document.body.appendChild(script);
	}

	public addMarkingAndReviewsSchema(): void {
		const script: HTMLScriptElement = this._document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.text = `{
			"@context": "http://schema.org",
			"type": "SoftwareApplication",
			"applicationCategory": "BusinessApplication",
			"name": "Copyleaks Plagiarism Checker",
			"description": "Detect plagiarism, paraphrased content,and similar text using sophisticated Artificial Intelligence(AI)based algorithms in 100 + languages with our online plagiarism checker.Integrations with LMSs, Open - Source API, In - Depth Search Technology, Duplicate file finder tool, OCR Technology",
			"aggregateRating": {
				 "@type": "AggregateRating",
				 "reviewCount": 77,
				 "ratingValue": 4.6,
				 "bestRating": 5
			},
			"operatingSystem": "Cloud, SaaS, Web"
	 }`;
		this._document.body.appendChild(script);
	}

	addVideoSchema() {
		const script: HTMLScriptElement = this._document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.text = `{
			"@context":"https://schema.org",
			"@type":"VideoObject",
			"name":"How does Copyleaks plagiarism checker work?",
			"description":"The Copyleaks plagiarism checker is able to detect all types of plagiarism using artificial intelligence technology. It will seamlessly detect plagiarism, even if it is unintentional plagiarism, and display a fully comprehensive report that will show the similar text that has been found, the similar percent, and allow you to compare your text against any other sources.",
			"thumbnailUrl":[
				 ${environment.mainWebsiteUrl}/assets/images/video-thumbnails/plagiarism-checker-page-thumbnail.png"
			],
			"uploadDate":"2020-03-27T08:00:00+08:00",
			"duration":"PT0M53S",
			"contentUrl":"https://www.youtube.com/watch?v=avLq0E8ygUc",
			"embedUrl":"https://www.youtube.com/embed/avLq0E8ygUc"
	 }`;
		this._document.body.appendChild(script);
	}

	addVideoPlagiarismCheckerSchema() {
		const script: HTMLScriptElement = this._document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.text = `{
			"@context":"https://schema.org",
			"@type":"VideoObject",
			"name":"How does Copyleaks plagiarism checker work?",
			"description":"The Copyleaks plagiarism checker is able to detect all types of plagiarism using artificial intelligence technology. It will seamlessly detect plagiarism, even if it is unintentional plagiarism, and display a fully comprehensive report that will show the similar text that has been found, the similar percent, and allow you to compare your text against any other sources.",
			"thumbnailUrl":[
				 ${environment.mainWebsiteUrl}/assets/images/video-thumbnails/plagiarism-checker-page-thumbnail.png"
			],
			"uploadDate":"2020-03-27T08:00:00+08:00",
			"duration":"PT0M53S",
			"contentUrl":"https://www.youtube.com/watch?v=avLq0E8ygUc",
			"embedUrl":"https://www.youtube.com/embed/avLq0E8ygUc"
	 }`;
		this._document.body.appendChild(script);
	}

	addMultipleCompareBreadcrumb() {
		const script: HTMLScriptElement = this._document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.text = `{
			"@context":"https://schema.org/",
			"@type":"BreadcrumbList",
			"itemListElement":[
				 {
						"@type":"ListItem",
						"position":1,
						"name":"Home",
						"item":"${environment.dashboardWebsiteUrl}/"
				 },
				 {
						"@type":"ListItem",
						"position":2,
						"name":"Text Compare",
						"item":"${environment.dashboardWebsiteUrl}/text-compare"
				 },
				 {
						"@type":"ListItem",
						"position":3,
						"name":"Compare Multiple Files",
						"item":"${environment.dashboardWebsiteUrl}/text-compare/compare-multiple-files"
				 }
			]
	 }`;
		this._document.body.appendChild(script);
	}

	addPreloadLinks(links: IPreloadLink[]) {
		for (const link of links) {
			const alreadyExists = this._document.querySelector(`link[href='${link.url}']`);
			if (!alreadyExists) {
				const preloadHTMLLink: HTMLLinkElement = this._document.createElement('link');
				preloadHTMLLink.setAttribute('rel', 'preload');
				preloadHTMLLink.setAttribute('as', link.as);
				preloadHTMLLink.setAttribute('href', link.url);

				const fontPreloadLink = this._document.querySelector(`link[as='style']`);
				this._insertAfterNode(preloadHTMLLink, fontPreloadLink);
			}
		}
	}

	addPrefetchLinks(links: IPrefetchLink[]) {
		for (const link of links) {
			const alreadyExists = this._document.querySelector(`link[href='${link.url}']`);
			if (!alreadyExists) {
				const preloadHTMLLink: HTMLLinkElement = this._document.createElement('link');
				preloadHTMLLink.setAttribute('rel', 'prefetch');
				preloadHTMLLink.setAttribute('href', link.url);

				const fontPreloadLink = this._document.querySelector(`link[as='style']`);
				this._insertAfterNode(preloadHTMLLink, fontPreloadLink);
			}
		}
	}

	addFaqPageSchema(questionAndAnswers: IQuestionsAndAnswers[]) {
		const script: HTMLScriptElement = this._document.createElement('script');
		script.setAttribute('type', 'application/ld+json');
		script.text = `{
			"@context": "https://schema.org/",
			"@type": "FAQPage",
			"mainEntity": [
				${questionAndAnswers.map(
					qa =>
						`{
						"@type": "Question",
						"name": "${qa.question}",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "${qa.answer}"
					}
				}`
				)}
			]
		}`;
		this._document.body.appendChild(script);
	}

	private _insertAfterNode(newNode, existingNode) {
		existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
	}
}

export interface IPreloadLink {
	url: string;
	as:
		| 'audio'
		| 'document'
		| 'embed'
		| 'fetch'
		| 'font'
		| 'image'
		| 'object'
		| 'script'
		| 'style'
		| 'track'
		| 'worker'
		| 'video';
}

export interface IPrefetchLink {
	url: string;
}

export interface IQuestionsAndAnswers {
	question: string;
	answer: string;
}

export interface BreadcrumbItem {
	name: string;
	url: string;
}
