import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@ngx-common/services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class UserRequiredMfaGuard implements CanActivate {
	constructor(private authService: AuthService, @Inject(PLATFORM_ID) private platformId: object) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (isPlatformServer(this.platformId)) {
			return true;
		}

		if (!this.authService?.IncompleteSecurityInfo$?.value) {
			this.authService.login();
			return false;
		} else return true;
	}
}
