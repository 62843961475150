import { environment } from '@environment';

export class NgxCommonPagesConfig {
	static key = 'NGX_COMMON_PAGES_CONFIG';
	public HOME_PAGE_ROUTING: string = '';
	public DISABLED_HEADER_TRANSLATIONS_LINK: boolean = true;
	public APP: ECopyleaksAPP = ECopyleaksAPP.MainWebsite;
	public DISABLED_OIDC_SERVICE: boolean = false;
	public IS_SSR_PROJECT = true;
	public APP_ORIGIN = environment.dashboardWebsiteUrl;
	public ACCOUNT_BILLING: string = '';
}

export enum ECopyleaksAPP {
	MainWebsite,
	API,
	LMS,
	Identity,
	Admin,
	AgentsDashboard,
}
