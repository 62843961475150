import { Injectable, ErrorHandler, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WindowService } from '../services/window.service';
import { ErrorHandlerOptions } from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { runOutsideAngular } from '../utils/zone';
import { AuthService } from '../services/auth.service';
import { isBrowserSupported } from '../utils/browser-support.utils';
import { CanCommunicateWithSentry } from '../utils/sentry';

@Injectable()
export class CustomSentryErrorHandler extends ErrorHandler {
	private readonly _options: ErrorHandlerOptions = {
		logErrors: true,
	};
	constructor(
		private _windowSvc: WindowService,
		authSvc: AuthService,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		super();

		authSvc.user$.subscribe(user => {
			if (user?.email && !!Sentry) {
				Sentry.setUser({
					email: user.email,
				});
			} else {
				Sentry.setUser(null);
			}
		});
	}
	handleError(error: any) {
		if (isPlatformBrowser(this.platformId)) {
			this._sendErrorToSentry(error);
		}
	}

	private _sendErrorToSentry(error: any) {
		const extractedError = this._extractError(error) || 'Handled unknown error';

		// log the error to console for immediate feedback.
		if (this._options.logErrors) {
			console.error(extractedError);
		}

		// check if it is ignored error message
		const errorMessage = error?.message ? error?.message : error;
		if (
			(typeof errorMessage === 'string' || errorMessage instanceof String) &&
			(!errorMessage.indexOf ||
				errorMessage.indexOf('Loading chunk') !== -1 || // already handled on this service
				errorMessage.indexOf('Undefined variable: localStorage') !== -1 || // no need to handle
				errorMessage.indexOf('Access is denied for this document') !== -1 || // no need to handle
				errorMessage.indexOf('This Element has already been destroyed. Please create a new one') !== -1 || // no need to handle
				errorMessage.indexOf('is not a supported CSS property for animations') !== -1 || // no need to handle
				errorMessage.indexOf('The play() request was interrupted by a call to pause()') !== -1 || // no need to handle
				errorMessage.indexOf('Error parsing handshake response') !== -1 || // handled on base notifications service
				errorMessage.indexOf('Server returned handshake error: Handshake was canceled') !== -1 || // handled on base notifications service
				errorMessage.indexOf('Expected a handshake response from the server') !== -1 || // handled on base notifications service
				errorMessage.indexOf('WebSocket closed with status code: 1006 ()') !== -1 || // handled on base notifications service
				errorMessage.indexOf('Invocation canceled due to the underlying connection being closed') !== -1 || // handled on base notifications service
				errorMessage.indexOf('Error: Server timeout elapsed without receiving a message from the server') !== -1 || // handled on base notifications service
				errorMessage.indexOf('ExpressionChangedAfterItHasBeenCheckedError') !== -1 || // this only happen in development
				errorMessage.indexOf('This Element has already been destroyed. Please create a new one') !== -1) // unhandled strip error, doesn't affect users
		) {
			return;
		}

		//here we're catching Unauthorized user exception in promise
		if (errorMessage.indexOf(':401') !== -1 && errorMessage.indexOf('HttpErrorResponse') !== -1) {
			return;
		}

		// supported browsers check
		const userAgent = this._windowSvc.window.navigator.userAgent;
		if (!isBrowserSupported(userAgent)) {
			return;
		}

		if (CanCommunicateWithSentry()) {
			// Capture handled exception and send it to Sentry.
			runOutsideAngular(() => Sentry.captureException(extractedError));
		}
	}

	protected _extractError(error: unknown): unknown {
		// Allow custom overrides of extracting function
		if (this._options.extractor) {
			const defaultExtractor = this._defaultExtractor.bind(this);
			return this._options.extractor(error, defaultExtractor);
		}

		return this._defaultExtractor(error);
	}

	/**
	 * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
	 */
	protected _defaultExtractor(errorCandidate: unknown): unknown {
		let error = errorCandidate;

		// Try to unwrap zone.js error.
		// https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
		if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
			error = (error as { ngOriginalError: Error }).ngOriginalError;
		}

		// We can handle messages and Error objects directly.
		if (typeof error === 'string' || error instanceof Error) {
			return error;
		}

		// If it's http module error, extract as much information from it as we can.
		if (error instanceof HttpErrorResponse) {
			// The `error` property of http exception can be either an `Error` object, which we can use directly...
			if (error.error instanceof Error) {
				return error.error;
			}

			// ... or an`ErrorEvent`, which can provide us with the message but no stack...
			if (error.error instanceof ErrorEvent && error.error.message) {
				return error.error.message;
			}

			// ...or the request body itself, which we can use as a message instead.
			if (typeof error.error === 'string') {
				return `Server returned code ${error.status} with body "${error.error}"`;
			}

			// If we don't have any detailed information, fallback to the request message itself.
			return error.message;
		}

		// Nothing was extracted, fallback to default error message.
		return null;
	}
}
