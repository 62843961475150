import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { OidcService } from '../services/oidc.service';

@Injectable({
	providedIn: 'root',
})
export class SSOIncompleteUserGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private _oidc: OidcService,
		@Inject(PLATFORM_ID) private platformId: object
	) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (isPlatformServer(this.platformId)) {
			return true;
		}
		if (!this.authService.SSOIncompleteUser$.value) {
			this._oidc.login();
			return false;
		} else {
			return true;
		}
	}
}
