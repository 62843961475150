export enum EAPIErrorCodes {
	InternalError = 'InternalError',
	CaptchaValidationError = 'CaptchaValidationError',
	DuplicateUserName = 'DuplicateUserName',
	Wrong = 'wrong',
	Permlock = 'permlock',
	Confirmation = 'confirmation',
	EmailNotConfirmed = 'noconfirmed',
	RegisterEmailNotConfirmed = 'email-not-activated',
	AlreadPartOfTeam = 'part-of-team',
	Templock = 'templock',
	TempEmail = 'tempemail',
	Expired = 'expired',
	InvitationsLimit = 'invitations-limit',
	NotAllowed = 'not-allowed',
	UnAuthorized = 'un-authorized',
	SessionEnd = 'session-end',
	UserNotExists = 'user-not-exists',
	Exists = 'exists',
	BadLogin = 'bad-login',
	CompareLimit = 'compare-limit',
	Bademail = 'bademail',
	AlreadyActivated = 'AlreadyActivated',
	RepositoryMaxCount = 'payment-required',
	BillingBlock = 'billing-block',
	TeamsPaymentRequired = 'teams-payment-required',
	PartialSuccess = 'PartialSuccess',
	ToEarly = 'toEarly',
	VerifyCodeError = 'verify-code-error',
	IncorrectPassword = 'incorrect-password',
	UnsupportedLang = 'unsupported-lang',
	UnsupportedLangForAnonymous = 'unsupported-lang-for-anonymous',
	TooShort = 'too-short',
	DuplicateDepartmentName = 'duplicate-department-name',
	PaymentReqiured = 'payment-required',
}
