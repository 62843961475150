import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environment';
import { UrlLocationService } from '@ngx-common/services/url-location.service';

@Injectable({
	providedIn: 'root',
})
export class ErrorPagesRedirectsGuard implements CanActivate {
	constructor(private _urlLocationSvc: UrlLocationService) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let _safeLocation = this._urlLocationSvc.location;
		if (state.url.startsWith('/teams')) {
			_safeLocation.href = `${environment.adminWebsiteUrl}/members`;
			return false;
		}

		if (state.url.startsWith('/repositories')) {
			_safeLocation.href = `${environment.adminWebsiteUrl}/repositories`;
			return false;
		}

		if (state.url.startsWith('/referral-program')) {
			_safeLocation.href = `${environment.mainWebsiteUrl}/affiliate`;
			return false;
		}
		return true;
	}
}
