export enum ELocalStorageKeys {
	User = 'user',
	Token = 'token',
	ScanSettings = 'scan-settings',
	DashboardSideNavMode = 'dashboard-side-nav-mode',
	ExtensionRateUsTriggered = 'ext-rate-triggered',
	SelectedProfile = 'selected-profile',
	StripClosed = 'strip-closed',
	DontShowRescanDialog = 'dont-show-rescan-dialog', // key for the list of scans that we will not show rescan dialog for them
	SignUpLeadName = 'signup-lead-name',
	PI = 'PI', // previus scan id - this has to be like that so others can't read it
	PT = 'PT', // previus scanned text - this has to be like that so others can't read it
	PD = 'PD', // previus scan datetime - this has to be like that so others can't read it
	ACDIToken = 'ACDIToken', // JWT Token for server validation
	ExtensionFirstUse = 'extention-installed',
	ExtensionScanCount = 'extention-scans-count',
	ExtensionReviewCanceledDate = 'extention-review-canceled-date',
	ExtensionReviewCancellationCount = 'extention-review-cancellation-count',
	ExtensionCanShowReview = 'extention-can-show-review',
}
