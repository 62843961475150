import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor(@Inject(PLATFORM_ID) private platformId: object) {}

	setItem(key: string, value: any, hashed = false) {
		if (isPlatformBrowser(this.platformId)) {
			if (hashed && btoa) {
				const encoder = new TextEncoder();
				const array = encoder.encode(value);
				localStorage.setItem(key, btoa(String.fromCharCode(...array)));
			} else {
				localStorage.setItem(key, value);
			}
		}
	}

	getItem(key: string, hashed = false) {
		if (isPlatformBrowser(this.platformId)) {
			if (hashed && atob) {
				var value = localStorage.getItem(key);
				const decodedBytes = Uint8Array.from(atob(value), c => c.charCodeAt(0));
				const decoder = new TextDecoder();
				const decodedString = decoder.decode(decodedBytes);
				return value ? decodedString : null;
			}
			return localStorage.getItem(key);
		}

		return null;
	}

	getParsedItem<T>(key: string): T {
		var itemString = localStorage.getItem(key);

		if (itemString) {
			return JSON.parse(itemString);
		}
		return null;
	}

	getIntegerParsedItem(key: string): number {
		var itemString = localStorage.getItem(key);

		if (itemString) {
			return parseInt(itemString);
		}
		return 0;
	}

	clear() {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.clear();
		}
	}

	removeItem(key: string) {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.removeItem(key);
		}
	}
}
