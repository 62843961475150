import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { UrlLocationService } from './url-location.service';
import { DevicesService } from './devices.service';
import { WindowService } from './window.service';
import { SUPPORTED_BROWSERS_USERAGENT_REGEXP } from '../constants';
export interface ILogError {
	pageUrl: string;
	error: string;
}

@Injectable({
	providedIn: 'root',
})
export class LogsService {
	constructor(
		private _http: HttpClient,
		private _deviceSvc: DevicesService,
		private _urlLocationSvc: UrlLocationService,
		private _windowSvc: WindowService
	) {}
	async sendPaymentLog(data: IPaymentLog) {
		try {
			await this._http.post(`${environment.apiUrl}/v1/logs/payment`, data).toPromise();
		} catch (error) {
			throw error;
		}
	}
	async logErrorToServer(error: string) {
		console.log(error);
	}
}

export interface IPaymentLog {
	paidVia: string;
}
