import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class UrlLocationService {
	constructor(@Inject(PLATFORM_ID) private platformId: object, private ngLocation: Location) {}

	get location() {
		if (isPlatformBrowser(this.platformId)) {
			return location;
		} else {
			return {
				hash: '',
				origin: '',
				href: '',
				pathname: '',
				search: '',
				hostname: '',
				reload: () => {},
			};
		}
	}

	get pathname() {
		if (isPlatformBrowser(this.platformId)) {
			return this.ngLocation.path();
		}
		return '';
	}
}
