import { DecimalPipe, UpperCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { CustomSnackbarModule } from '@ngx-common/components/custom-snackbar/custom-snackbar.module';
import { JwtInterceptor } from '@ngx-common/interceptors/jwt.interceptor';
import { UnauthorizedInterceptor } from '@ngx-common/interceptors/unauthorized.interceptor';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '@ngx-common/pages/pages.config';
import { CustomSentryErrorHandler } from '@ngx-common/services/custom-sentry-handler.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import * as Sentry from '@sentry/angular';
import { from, Observable } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutRouting } from './main-layout/models/main-layout-routing.model';
export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		if (lang === 'en-US') {
			lang = 'en';
		}
		return from(import(`../assets/i18n/${lang}.json`));
	}
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		MatSnackBarModule,
		CustomSnackbarModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader,
			},
		}),
		MatProgressBarModule,
		CustomSnackbarModule,
		MatSnackBarModule,
		ScrollToModule.forRoot(),
		HammerModule,
	],
	providers: [
		DecimalPipe,
		UpperCasePipe,
		{
			provide: NgxCommonPagesConfig.key,
			useValue: {
				HOME_PAGE_ROUTING: MainLayoutRouting.PERSONAL_INFORMATION,
				APP: ECopyleaksAPP.Identity,
				APP_ORIGIN: environment.idUrl,
				IS_SSR_PROJECT: false,
			} as NgxCommonPagesConfig,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		// ** Sentry Error Logging **
		{
			provide: ErrorHandler,
			useClass: CustomSentryErrorHandler,
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
			useValue: undefined,
		},
		// *************************
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
