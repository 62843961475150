import { Direction } from '@angular/cdk/bidi';
import { getLocaleDirection } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AppDirectionalityService {
	private _value: Direction;
	public get value(): Direction {
		return this._value;
	}

	constructor(@Inject(LOCALE_ID) private _locale: string) {
		this._value = getLocaleDirection(this._locale);
	}
}
