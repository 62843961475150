import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DataSharingService {
	private _sharedData = new Map<string, any>();
	constructor() {}

	set(key: string, value: any) {
		this._sharedData.set(key, value);
	}
	get(key: string) {
		return this._sharedData.get(key);
	}
	remove(key: string) {
		this._sharedData.delete(key);
	}
	getAndRemove(key: string) {
		const value = this._sharedData.get(key);
		this._sharedData.delete(key);
		return value;
	}
	clear() {
		this._sharedData = new Map<string, any>();
	}
}
