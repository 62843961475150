import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ESessionStorageKeys } from '../enums/session-storage-keys.enum';
import { AuthService } from '../services/auth.service';
import { SessionStorageService } from '../services/session-storage.service';

export enum EAuthenticationHeaders {
	ReadOnlyReport = 'read-only-report',
}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private _authService: AuthService, private _sessionStorageSvc: SessionStorageService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let authenticationToken: string;
		if (
			request.headers.get(EAuthenticationHeaders.ReadOnlyReport) &&
			request.headers.get(EAuthenticationHeaders.ReadOnlyReport) === 'true'
		) {
			// get auth token read only reports
			authenticationToken = this._sessionStorageSvc.getItem(ESessionStorageKeys.ReadOnlyReportToken);
		} else {
			// get auth token for users
			authenticationToken = this._authService.token;
		}
		if (authenticationToken) {
			// add authorization header with jwt token if available
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${authenticationToken}`,
				},
			});
		}
		return next.handle(request);
	}
}
