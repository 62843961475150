import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { User } from '@sentry/angular';
import { CookieService } from 'ngx-cookie-service';
import { ECookiesKeys } from '../enums/cookies-keys.enum';
import { ECopyleaksEmailStatuses } from '../enums/email-statuses.enum';
import { ELocalStorageKeys } from '../enums/local-storage-keys.enum';
import { ERegisterationSource } from '../enums/registeration-source.enum';
import { EWalletType } from '../enums/wallet-type.enum';
import { IPasswordPolicy } from '../interfaces/interfaces.shared';
import { ConfirmAccountRes, ActivateAccountRes as OtacAccountRes } from '../models/auth.model';
import { IUserActivationDetailsFormData, IUserDetailsFormData } from '../models/user.models';
import { httpFailRetry } from '../observable/http-fail-retry.pipes';
import { EnvironmentService } from './enviroments.service';
import { IUserSubscriptionState } from './payment-plan.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private _activateRes: OtacAccountRes;
	set activateRes(value: OtacAccountRes) {
		this._activateRes = value;
	}
	get activateRes(): OtacAccountRes {
		return this._activateRes;
	}

	constructor(private _http: HttpClient, private _cookieSvc: CookieService, private _envSvc: EnvironmentService) {}

	async register(data: IRegisterData) {
		try {
			data.refId = this._cookieSvc.get(ECookiesKeys.RefId);

			const result = await this._http
				.post<EmailResult>(`${environment.apiUrl}/v1/account/register`, {
					...data,
				})
				.toPromise();

			if (data.refId) {
				this._cookieSvc.delete(ECookiesKeys.RefId);
			}
			return result;
		} catch (error) {
			throw error;
		}
	}

	async guestConnectAsync(data: IRegisterData) {
		try {
			data.refId = this._cookieSvc.get(ECookiesKeys.RefId);

			const result = await this._http
				.post<IGuestConnectResponse>(`${environment.apiUrl}/v1/account/guest/connect`, {
					...data,
				})
				.toPromise();

			if (data.refId) {
				this._cookieSvc.delete(ECookiesKeys.RefId);
			}
			return result;
		} catch (error) {
			throw error;
		}
	}

	async guestRegisterAsync(data: IGuestRegisterData) {
		data.refId = this._cookieSvc.get(ECookiesKeys.RefId);

		const result = await this._http
			.post<OtacAccountRes>(`${environment.apiUrl}/v1/account/guest/register`, {
				...data,
			})
			.toPromise();

		if (data.refId) {
			this._cookieSvc.delete(ECookiesKeys.RefId);
		}
		return result;
	}

	async verifyEmailWithCodeAsync(code: string, email: string) {
		return await this._http
			.post<ConfirmAccountRes>(`${environment.apiUrl}/v1/account/confirm-account`, {
				token: code,
				email,
			})
			.toPromise();
	}

	async guestVerifyEmailWithCodeAsync(code: string, verifyOwnerShipToken: string) {
		return await this._http
			.post<ConfirmAccountRes>(`${environment.apiUrl}/v1/account/guest/confirm-account`, {
				token: code,
				verifyOwnerShipToken,
			})
			.toPromise();
	}

	async activateAccountViaTokenAsync(
		data: IUserDetailsFormData,
		token: string,
		promRefId?: string,
		promTId?: string,
		cRef?: string
	) {
		try {
			return await this._http
				.post<OtacAccountRes>(`${environment.apiUrl}/v3/account/activate`, {
					email: data.email,
					firstName: data.firstName,
					lastName: data.lastName,
					profileType: data.profileType,
					company: data.company,
					marketingEmails: data.marketingEmails,
					token,
					countryCode: data.country,
					promRefId: promRefId,
					promTId: promTId,
					cRef: cRef,
				})
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	async activatePasswordAccountViaTokenAsync(data: IUserActivationDetailsFormData, token: string) {
		try {
			return await this._http
				.post<OtacAccountRes>(`${environment.apiUrl}/v3/account/password/activate`, {
					email: data.email,
					firstName: data.firstName,
					lastName: data.lastName,
					profileType: data.profileType,
					company: data.company,
					password: data.newPassword,
					marketingEmails: data.marketingEmails,
					token,
					countryCode: data.country,
				})
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	async submitAdditionInfoAsync(data: IUserDetailsFormData, promRefId?: string, promTId?: string, cRef?: string) {
		try {
			return await this._http
				.post<User>(`${environment.apiUrl}/v3/account/submit-additional-info`, {
					firstName: data.firstName,
					lastName: data.lastName,
					profileType: data.profileType,
					company: data.company,
					marketingEmails: data.marketingEmails,
					countryCode: data.country,
					promRefId: promRefId,
					promTId: promTId,
					cRef: cRef,
				})
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	async resendActivationCode(data: ResendActivationEmail) {
		try {
			const results = await this._http
				.post<EmailResult>(`${environment.apiUrl}/v1/account/resend-activation-email`, data)
				.toPromise();
			return results;
		} catch (error) {
			throw error;
		}
	}

	async guestResendActivationCode(verifyOwnerShipToken: string) {
		try {
			const results = await this._http
				.post<EmailResult>(`${environment.apiUrl}/v1/account/guest/resend-activation-email`, {
					verifyOwnerShipToken,
				})
				.toPromise();
			return results;
		} catch (error) {
			throw error;
		}
	}

	async sendSubscriptionFeedback(data: ISubscriptionFeedbackData) {
		try {
			return await this._http.post(`${environment.apiUrl}/v1/account/feedbacks/subscription/cancel`, data).toPromise();
		} catch (error) {
			throw error;
		}
	}

	async getPasswordPolicyAsync(email: string) {
		try {
			return await this._http
				.get<IPasswordPolicy>(
					`${environment.apiUrl}/v1/account/password/policy/email?email=${encodeURIComponent(email)}`
				)
				.pipe(httpFailRetry())
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	async GetReturnWebsiteUrl(email: string) {
		try {
			return await this._http
				.get<ReturnWebsiteUrl>(`${environment.apiUrl}/v1/account/${email}/activate/return-website`)
				.pipe(httpFailRetry())
				.toPromise();
		} catch (error) {
			throw error;
		}
	}

	storeSignUpLeadName(lead: string) {
		if (!lead) return;
		this._cookieSvc.set(ELocalStorageKeys.SignUpLeadName, lead, 30, '/', this._envSvc.getDomain());
	}
	removeSignUpLeadName() {
		this._cookieSvc.delete(ELocalStorageKeys.SignUpLeadName);
	}
	getSignUpLeadName(): string {
		return this._cookieSvc.get(ELocalStorageKeys.SignUpLeadName);
	}
}

export interface IRegisterData {
	email: string;
	password: string;
	captchaResponse?: string;
	registeringFrom?: string;
	isAdmin?: boolean;
	refId?: string;
	firstName?: string;
	lastName?: string;
	countryCode?: string;
	company?: string;
	activationQueryParams?: { [key: string]: any };
	registerationSource?: ERegisterationSource;
	registrationMode?: eRegistrationMode;
}

export interface IGuestRegisterData {
	refId?: string;
	saveScanTextModel?: IGuestSaveScanTextModel;
	captchaResponse?: string;
}

export enum eRegistrationMode {
	OffPaySession = 1,
	OnPaySession = 2,
}
export interface IResetPasswordPayloads {
	token: string;
	newPassword: string;
	captchaResponse?: string;
}

export interface IFeedbackDialogData {
	email: string;
	selection: string;
	feedback: string;
}

export interface ISubscriptionFeedbackDialogData {
	email: string;
	subscription: IUserSubscriptionState;
	product: EWalletType;
}
export interface ISubscriptionFeedbackData {
	email: string;
	plan: string;
	selection: string;
	feedback: string;
	nextBilling: Date;
	option: ELikelyOptions;
	profileType: string;
	productType?: EWalletType;
}

export interface ICopyleaksStatusModel {
	status: ECopyleaksEmailStatuses;
	showResendEmailButton?: boolean;
	retryToSignUpAgainButton?: boolean;
}

export interface IChangeEmailData {
	token: string;
}

export interface IRegisterAccessToReportResponse {
	token: string;
}

export interface IGetEmailStatusData {
	trackingCode: string;
}
export interface IPasswordEmailPolicyData {
	email: string;
}
export interface IPasswordTokenPolicyData {
	token: string;
}

export interface IGuestConnectResponse {
	token: string;
}

export interface IGuestSaveScanTextModel {
	fileId: string;
	htmlText: string;
}

export enum ELikelyOptions {
	ExtremelyLikely = 2,
	Likely = 1,
	Neutral = 0,
	Unlikely = -1,
	ExtremelyUnikely = -2,
}

export enum EActivateOption {
	Regular,
	Refer,
	Group,
}

export interface EmailResult {
	id: string;
	provider: EEmailProviders;
}

export enum EEmailProviders {
	SendInBlue = 0,
	Mailgun = 1,
}

export enum ECopyleaksWebsites {
	MainWebsite = 0,
	ApiWebsite = 1,
}

export interface ReturnWebsiteUrl {
	website: ECopyleaksWebsites;
}

export interface ResendActivationEmail {
	email: string;
}
