import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { CookieService } from 'ngx-cookie-service';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { Subscription } from 'rxjs';
import { ECookiesKeys } from '../enums/cookies-keys.enum';
import { ELocalStorageKeys } from '../enums/local-storage-keys.enum';
import { ERegisterationSource } from '../enums/registeration-source.enum';
import { EUserKind } from '../enums/user-kinds.enum';
import { UserInfo } from '../models/auth.model';
import { AccountPagesRoutes } from '../pages/account-pages/model/account-routings.model';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../pages/pages.config';
import { EnvironmentService } from './enviroments.service';
import { LocalStorageService } from './localstorage.service';
import { UrlLocationService } from './url-location.service';
import { ECopyleaksWebsites, ReturnWebsiteUrl } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class OidcService {
	public manager: UserManager;
	private user: User = null;
	enviromentLoadSubscription: Subscription;

	constructor(
		@Inject(PLATFORM_ID) private _platformId: string,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig,
		private _localStorageSvc: LocalStorageService,
		private _router: Router,
		private _cookieService: CookieService,
		private _envSvc: EnvironmentService,
		private _urlLocationSvc: UrlLocationService,
		private _clsAlertsSvc: ClsAlertsService
	) {
		this.initUser();
	}

	private initUser() {
		if (isPlatformBrowser(this._platformId) && !this._config.DISABLED_OIDC_SERVICE) {
			this.enviromentLoadSubscription = this._envSvc.isEnviromentsLoaded$.subscribe(isloaded => {
				if (isloaded) {
					this.manager = new UserManager(this._getClientSettings());
					this.manager
						.getUser()
						.then(user => {
							this.user = user;
						})
						.catch(err => {
							this._clsAlertsSvc.showCustomError(err);
						});
				}
			});
		}
	}

	private _createManager(settings: UserManagerSettings) {
		this.manager = new UserManager(settings);
		this.manager.events.addUserSignedOut(
			function () {
				this.login();
			}.bind(this)
		);
	}

	isLoggedIn(): boolean {
		if (isPlatformBrowser(this._platformId)) {
			return this.user != null && !this.user.expired;
		}
	}

	autoLogin(otac: string, redirectUrl?: string) {
		if (isPlatformBrowser(this._platformId)) {
			this._createManager(this._getClientSettings(otac, null));
			if (redirectUrl) this.loginWithReturnUrl(redirectUrl);
			else this.login(false);
		}
	}

	externalAutoLogin(otac: string, website: ReturnWebsiteUrl) {
		if (isPlatformBrowser(this._platformId)) {
			let loginUrl;
			switch (website.website) {
				case ECopyleaksWebsites.ApiWebsite:
					loginUrl = `${environment.apiFrontEndUrl}`;
					break;
				case ECopyleaksWebsites.MainWebsite:
					loginUrl = `${environment.dashboardWebsiteUrl}`;
					break;
			}
			location.href = `${loginUrl}/${AccountPagesRoutes.LoginRedirect}?otac=${otac}`;
		}
	}

	login(redirectToCurrentPath = true) {
		this._router.navigate([`/${AccountPagesRoutes.LoginRedirect}`], {
			queryParams: {
				isLogin: true,
				redirect: redirectToCurrentPath ? this._getCurrentRedirectFullPath() : null,
			},
		});
	}

	loginWithReturnUrl(returnUrl: string) {
		this._router.navigate([`/${AccountPagesRoutes.LoginRedirect}`], {
			queryParams: {
				isLogin: true,
				redirect: returnUrl,
			},
		});
	}

	socialLogin(ssoProvider: ESSOProviders, redirectUrl?: string, regSource: ERegisterationSource = null) {
		this._router.navigate([`/${AccountPagesRoutes.LoginRedirect}`], {
			queryParams: {
				isLogin: true,
				redirect: redirectUrl ? redirectUrl : this._getCurrentRedirectFullPath(),
				ssoProvider,
				regSource: this._getRegSource(regSource),
			},
		});
	}

	_getRegSource(regSource) {
		if (regSource) return regSource;
		if (this._config.APP === ECopyleaksAPP.MainWebsite) {
			return ERegisterationSource.Website;
		}
		if (this._config.APP === ECopyleaksAPP.API) {
			return ERegisterationSource.WebsiteAPI;
		}
		return '';
	}

	getUserInfoLogin(redirectToCurrentPath = true, redirectUrl: string = null) {
		this._localStorageSvc.removeItem(ELocalStorageKeys.User);
		this._router.navigate([`/${AccountPagesRoutes.LoginRedirect}`], {
			queryParams: {
				isGetUserInfo: true,
				redirect: redirectToCurrentPath
					? this._getCurrentRedirectFullPath()
					: redirectUrl
					? encodeURIComponent(redirectUrl)
					: null,
			},
		});
	}

	private _getCurrentRedirectFullPath() {
		return this._urlLocationSvc.location.pathname + this._urlLocationSvc.location.search;
	}

	getClaims(): any {
		if (isPlatformBrowser(this._platformId)) {
			return this.user?.profile;
		}
	}

	getToken(): string {
		if (isPlatformBrowser(this._platformId)) {
			if (!this.user) {
				return '';
			}
			return `${this.user?.access_token}`;
		}
		return '';
	}

	async getTokenAsync() {
		if (isPlatformBrowser(this._platformId)) {
			if (!this.manager) this.manager = new UserManager(this._getClientSettings());
			this.user = await this.manager.getUser();
			if (!this.user) {
				return '';
			}
			return `${this.user?.access_token}`;
		}
		return '';
	}

	getRedirectUrl(): string {
		if (isPlatformBrowser(this._platformId)) {
			if (!this.user) {
				return '';
			}
			return `${this.user?.state ?? ''}`;
		}
		return '';
	}

	getIdToken(): string {
		if (isPlatformBrowser(this._platformId)) {
			if (!this.user) {
				return '';
			}
			return `${this.user?.id_token ?? ''}`;
		}
		return '';
	}

	getSessionId(): string {
		if (isPlatformBrowser(this._platformId)) {
			if (!this.user) {
				return '';
			}
			return `${this.user?.profile?.sid}`;
		}
		return '';
	}

	async startAuthentication(redirectUrl: string) {
		if (isPlatformBrowser(this._platformId)) {
			const res = await this.manager.signinRedirect({
				state: redirectUrl ? decodeURIComponent(redirectUrl) : '',
			});
			this._createManager(this._getClientSettings());
			return res;
		}
	}

	async startSocialLoginAuthentication(
		redirectUrl: string,
		ssoProvider: ESSOProviders,
		regSource: ERegisterationSource
	) {
		if (isPlatformBrowser(this._platformId)) {
			var settings = this._getClientSettings(null, ssoProvider, regSource);
			this._createManager(settings);
			const res = await this.manager.signinRedirect({
				state: redirectUrl ? decodeURIComponent(redirectUrl) : '',
			});
			this._createManager(this._getClientSettings());
			return res;
		}
	}

	async completeAuthentication(): Promise<void> {
		if (isPlatformBrowser(this._platformId)) {
			const user = await this.manager.signinRedirectCallback();
			this.user = user;
			return;
		}
	}

	logout() {
		if (isPlatformBrowser(this._platformId)) {
			this._router.navigate([`/${AccountPagesRoutes.LoginRedirect}`], {
				queryParams: {
					isLogout: true,
				},
			});
		}
	}

	private _getClientSettings(otac = null, ssoProvider = null, regSource?: ERegisterationSource): UserManagerSettings {
		if (isPlatformBrowser(this._platformId)) {
			var baseURL = location.origin;
			let redirect_uri = `${baseURL}/${AccountPagesRoutes.LoginRedirect}`;

			let post_logout_redirect_uri = `${baseURL}`;

			if (this._config.APP === ECopyleaksAPP.MainWebsite) {
				post_logout_redirect_uri = `${environment.mainWebsiteUrl}`;
			}

			if (this._config.APP === ECopyleaksAPP.MainWebsite) {
				const language = this._cookieService.get(ECookiesKeys.Langauge);
				if (language && language != 'en' && language != 'en-US') {
					redirect_uri = `${baseURL}/${language}/${AccountPagesRoutes.LoginRedirect}`;
					post_logout_redirect_uri = `${post_logout_redirect_uri}/${language}/`;
				}
			}

			let acrValues = null;

			if (ssoProvider) {
				acrValues = `ssoProvider:${ssoProvider}:`;
				if (this._config.APP === ECopyleaksAPP.MainWebsite && this.isCurrentUserASGuest())
					acrValues += `guestSub:${this.user.profile.sub}:`;
			} else if (otac) {
				acrValues = `otp:${otac}:`;
			}
			if (regSource) {
				acrValues += `regSource:${regSource}:`;
			}

			return {
				authority: environment.authUrl,
				client_id: 'websites-copyleaks.com',
				redirect_uri,
				post_logout_redirect_uri,
				response_type: 'code',
				scope: `openid profile email ${this.getRelevantScopes()} role`,
				filterProtocolClaims: true,
				loadUserInfo: false,
				userStore: new WebStorageStateStore({ store: window.localStorage }),
				stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
				revokeAccessTokenOnSignout: true,
				acr_values: acrValues,
				monitorSession: false,
			};
		} else {
			return {};
		}
	}

	getRelevantScopes(): string {
		switch (this._config.APP) {
			case ECopyleaksAPP.MainWebsite:
				return 'backend-copyleaks.com notifications.copyleaks.com';
			case ECopyleaksAPP.API:
				return 'backend-api.copyleaks.com notifications.copyleaks.com';
			case ECopyleaksAPP.LMS:
				return 'lms-copyleaks.com';
			case ECopyleaksAPP.Identity:
				return 'id.copyleaks.com notifications.copyleaks.com';
			case ECopyleaksAPP.Admin:
				return 'backend-admin-copyleaks.com notifications.copyleaks.com';
		}
	}

	getUserFromLocalStorage(): UserInfo {
		return JSON.parse(this._localStorageSvc.getItem(ELocalStorageKeys.User));
	}

	public isCurrentUserASGuest(): boolean {
		var user = this.getUserFromLocalStorage();
		if (!user) return false;
		return user.userKind == EUserKind.GuestAccount;
	}
}

export enum ESSOProviders {
	Facebook,
	Google,
	SSO,
}
