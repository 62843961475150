import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { NgxCommonPagesConfig } from '../pages/pages.config';
import { SEOService } from '../services/seo.service';

@Injectable({
	providedIn: 'root',
})
export class SEOGuard implements CanActivate, CanActivateChild {
	constructor(
		private _seoService: SEOService,
		@Inject(NgxCommonPagesConfig.key) private config: NgxCommonPagesConfig
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		let url = state.url;
		//#region remove query params
		if (url.includes('?')) {
			url = url.slice(0, url.indexOf('?'));
		}
		//#endregion remove query params
		if (route.hasOwnProperty('data') && route.data.meta && route.data.meta.title) {
			this._seoService.setPageTitle(route.data.meta.title);
		}

		if (route.hasOwnProperty('data') && route.data.meta && route.data.meta.description) {
			this._seoService.createMetaTagForDescription(route.data.meta.description);
		}

		if (route.hasOwnProperty('data') && route.data.meta && route.data.meta.keywords) {
			this._seoService.createMetaTagForKeywords(route.data.meta.keywords);
		}

		if (route.hasOwnProperty('data') && route.data.meta && route.data.meta.imageUrl) {
			this._seoService.createMetaTagForImageUrl(route.data.meta.imageUrl);
		}

		if (route.hasOwnProperty('data') && route.data.meta && route.data.meta.disableRobots) {
			this._seoService.disableRobots();
		}

		if (
			route.hasOwnProperty('data') &&
			route.data.meta &&
			route.data.meta.preloadLinks &&
			route.data.meta.preloadLinks.length
		) {
			this._seoService.addPreloadLinks(route.data.meta.preloadLinks);
		}

		if (
			route.hasOwnProperty('data') &&
			route.data.meta &&
			route.data.meta.prefetchLinks &&
			route.data.meta.prefetchLinks.length
		) {
			this._seoService.addPrefetchLinks(route.data.meta.prefetchLinks);
		}

		if (route?.data?.meta?.exploreCanonical) {
			this._seoService.createLinkForExplorePages(`${this.config.APP_ORIGIN}${url}`);
		}

		if (!route?.data?.meta?.disableCanonical && !route?.data?.meta?.exploreCanonical) {
			if (route?.data?.meta?.disableCanonicalTranslation) {
				this._seoService.createLinkForCanonicalURL(`${this.config.APP_ORIGIN}${url}`, false);
			} else {
				this._seoService.createLinkForCanonicalURL(`${this.config.APP_ORIGIN}${url}`);
			}
		}

		if (route.hasOwnProperty('data') && route.data.meta && route.data.meta.noFollow) {
			this._seoService.createLinkForNoFollowUrl();
		} else {
			if (!this.config.DISABLED_HEADER_TRANSLATIONS_LINK) {
				this._seoService.createLinkForHrefLang(`${this.config.APP_ORIGIN}${url}`);
			}
		}

		return true;
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
