import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { EnvironmentService } from '../services/enviroments.service';

@Injectable({
	providedIn: 'root',
})
export class EnviromentsLoadGuard implements CanActivate {
	constructor(private _environmentService: EnvironmentService, private _clsAlertsSvc: ClsAlertsService) {}
	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		try {
			await this._environmentService.initEnvironementVariables();
			return true;
		} catch (err) {
			this._clsAlertsSvc.showHttpResponseError(err);
			return false;
		}
	}
}
