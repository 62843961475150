import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class WindowService {
	constructor(@Inject(PLATFORM_ID) private platformId: object) {}

	get window() {
		if (isPlatformBrowser(this.platformId)) {
			return window;
		} else {
			return {
				addEventListener: this.addEventListener.bind(this),
				removeEventListener: this.removeEventListener.bind(this),
				open: this.open.bind(this),
				focus: this.focus.bind(this),
				navigator: this.navigator.bind(this),
				innerHeight: 0,
				innerWidth: 0,
				parent: { location: { href: '' } },
			};
		}
	}

	addEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject,
		options: boolean | AddEventListenerOptions = null
	) {
		if (isPlatformBrowser(this.platformId)) {
			window?.addEventListener(type, listener, options);
		}
	}

	removeEventListener(
		type: string,
		listener: EventListenerOrEventListenerObject,
		options: boolean | AddEventListenerOptions = null
	) {
		if (isPlatformBrowser(this.platformId)) {
			window?.removeEventListener(type, listener, options);
		}
	}

	open(url?: string, target?: string, features?: string, replace?: boolean) {
		if (isPlatformBrowser(this.platformId)) {
			return window?.open(url, target, features); // replace is not used
		}
	}

	openInNewTab(url?: string) {
		if (isPlatformBrowser(this.platformId)) {
			return window?.open(url);
		}
	}

	navigator(): Navigator {
		if (isPlatformBrowser(this.platformId)) {
			return window?.navigator;
		}
	}

	focus() {
		if (isPlatformBrowser(this.platformId)) {
			window?.focus();
		}
	}

	scrollTo(x: number, y: number) {
		if (isPlatformBrowser(this.platformId)) {
			window?.scrollTo(x, y);
		}
	}

	getScrollY(): number {
		if (isPlatformBrowser(this.platformId)) {
			return window?.scrollY;
		}
	}
}
