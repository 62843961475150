import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { environment } from '@environment';
import { EUserKind } from '@ngx-common/enums/user-kinds.enum';
import { UserInfo } from '@ngx-common/models/auth.model';
import { AuthService } from '@ngx-common/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GuestUserGuard implements CanActivate, CanActivateChild {
	constructor(private _router: Router, private _authSvc: AuthService) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		var localStorageUser = this._authSvc.getUserFromLocalStorage();
		if (localStorageUser) {
			const user = JSON.parse(localStorageUser);
			if ((user as UserInfo)?.userKind == EUserKind.GuestAccount) {
				this._authSvc.clearUserData();
				location.href = `${environment.dashboardWebsiteUrl}`;
				return false;
			}
		}
		return true;
	}
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.canActivate(childRoute, state);
	}
}
