export const MAX_REFILLS = 1000;
export const FREE_PLAN_ID = '1';
export const STRIPE_NEW_CARD_ID = '-1';

export const POSTAL_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const CIRD_REGEX = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;
export const EMAIL_REGEX =
	/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
export const MAX_FILE_SIZE = 50000000; // 50MB
export const NUMBER_OF_FILES_TO_ASK_FOR_FOLDER = 4;

export const URL_REGEX =
	/^(http|https|ftp|ftps):\/\/(([a-zA-Z0-9$\-_.+!*'(),;:&=]|%[0-9a-fA-F]{2})+@)?(((25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])(\.(25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])){3})|localhost|([a-zA-Z0-9\-00C017F]+\.)+([a-zA-Z]{2,}))(:[0-9]+)?(\/(([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*(\/([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*)*)?(\?([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?(\#([a-zA-Z0-9$\-_.+!*'(),;:@&=\/?]|%[0-9a-fA-F]{2})*)?)?$/;
export const URL_REGEX_WITH_OPTIONAL_PROTOCOL = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
export const IP_ADDRESS_REGEX = /^(?=\d+\.\d+\.\d+\.\d+($|\/))(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])\.?){4}?$/gm;
export const IP_ADDRESS_WITH_PORT_REGEX =
	/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]+$/g;
export const HOST_NAME_REGEX = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/gim;
export const NAME_VALIDATION_REGEX: string = "[A-Za-z ,.'_-]+";

export const MAX_BATCH_FILES = 100;
export const MIN_BATCH_FILES = 2;
export const MAX_SCAN_FILES = 2000;
export const MAX_SCAN_URLS = 10000;
export const MAX_SCANS_PER_CHUNK = 1000;
export const MAX_INDEX_FILES = 2500;

export const REPO_ID_REGEX = /^[-_A-Za-z0-9]*$/;

export const PROJECT = 'ZG90bmV0LWNvcmUtY2x1c3Rlcg==';
export const MAX_CONTAINER_NOTIFICATIONS_TO_SHOW = 15;
export const MAX_PROGRESS_NOTIFICATIONS_TO_SHOW = 100;

export const EXTENSIONS_WITH_ICONS = [
	'bmp',
	'chm',
	'css',
	'csv',
	'doc',
	'docx',
	'epub',
	'jpeg',
	'jpg',
	'latx',
	'odp',
	'odt',
	'pdf',
	'png',
	'pdf',
	'ppsx',
	'ppt',
	'pptx',
	'rtf',
	'txt',
	'xls',
	'xlsx',
];

export const GOOGLE_SCRIPTS = {
	STACK_DRIVER_ERROR: 'https://cdn.jsdelivr.net/npm/stackdriver-errors-js@0.7.0/dist/stackdriver-errors-concat.min.js',
};

export const DEFAULT_HASH_TAGS_SELECTORS = 'h1, h2, h3, h4, h5, h6, th';

export const SUPPORTED_BROWSERS_USERAGENT_REGEXP = // generated via 'npm run supportedBrowsers' on the main website
	/((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(10[_.]0|10[_.]([1-9]|\d{2,})|10[_.]2|10[_.]([3-9]|\d{2,})|(1[1-9]|[2-9]\d|\d{3,})[_.]\d+|11[_.]0|11[_.]([1-9]|\d{2,})|11[_.]2|11[_.]([3-9]|\d{2,})|(1[2-9]|[2-9]\d|\d{3,})[_.]\d+|12[_.]0|12[_.]([1-9]|\d{2,})|12[_.]4|12[_.]([5-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})[_.]\d+|13[_.]0|13[_.]([1-9]|\d{2,})|13[_.]7|13[_.]([8-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})[_.]\d+|14[_.]0|14[_.]([1-9]|\d{2,})|14[_.]4|14[_.]([5-9]|\d{2,})|14[_.]7|14[_.]([8-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})[_.]\d+)(?:[_.]\d+)?)|(CFNetwork\/808\.(\d))|(CFNetwork\/8.* Darwin\/16\.5\.\d+)|(CFNetwork\/8.* Darwin\/16\.6\.\d+)|(CFNetwork\/8.* Darwin\/16\.7\.\d+)|(CFNetwork\/8.* Darwin\/16\.\d+)|(CFNetwork\/8.* Darwin\/17\.0\.\d+)|(CFNetwork\/8.* Darwin\/17\.2\.\d+)|(CFNetwork\/8.* Darwin\/17\.3\.\d+)|(CFNetwork\/8.* Darwin\/17\.\d+)|(Opera Mini(?:\/att)?\/?(\d+)?(?:\.\d+)?(?:\.\d+)?)|(Opera\/.+Opera Mobi.+Version\/(64\.0|64\.([1-9]|\d{2,})|(6[5-9]|[7-9]\d|\d{3,})\.\d+))|(Opera\/(64\.0|64\.([1-9]|\d{2,})|(6[5-9]|[7-9]\d|\d{3,})\.\d+).+Opera Mobi)|(Opera Mobi.+Opera(?:\/|\s+)(64\.0|64\.([1-9]|\d{2,})|(6[5-9]|[7-9]\d|\d{3,})\.\d+))|(SamsungBrowser\/(8\.2|8\.([3-9]|\d{2,})|(9|\d{2,})\.\d+|10\.1|10\.([2-9]|\d{2,})|(1[1-9]|[2-9]\d|\d{3,})\.\d+|11\.2|11\.([3-9]|\d{2,})|(1[2-9]|[2-9]\d|\d{3,})\.\d+|12\.0|12\.([1-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})\.\d+))|(Edge\/(86(?:\.0)?|86(?:\.([1-9]|\d{2,}))?|(8[7-9]|9\d|\d{3,})(?:\.\d+)?))|((Chromium|Chrome)\/(86\.0|86\.([1-9]|\d{2,})|(8[7-9]|9\d|\d{3,})\.\d+)(?:\.\d+)?)|(Version\/(10\.0|10\.([1-9]|\d{2,})|(1[1-9]|[2-9]\d|\d{3,})\.\d+|11\.0|11\.([1-9]|\d{2,})|(1[2-9]|[2-9]\d|\d{3,})\.\d+|12\.0|12\.([1-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})\.\d+|13\.0|13\.([1-9]|\d{2,})|(1[4-9]|[2-9]\d|\d{3,})\.\d+|14\.0|14\.([1-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})\.\d+)(?:\.\d+)? Safari\/)|(Firefox\/(85\.0|85\.([1-9]|\d{2,})|(8[6-9]|9\d|\d{3,})\.\d+)\.\d+)|(Firefox\/(85\.0|85\.([1-9]|\d{2,})|(8[6-9]|9\d|\d{3,})\.\d+)(pre|[ab]\d+[a-z]*)?)/;
