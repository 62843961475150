import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnviromentsLoadGuard } from '@ngx-common/guards/enviroments.guard';
import { SSOIncompleteBillingDetailsUserGuard } from '@ngx-common/guards/incomplete-billing-details-user.guard';
import { SEOGuard } from '@ngx-common/guards/seo.guard';
import { SSOIncompleteUserGuard } from '@ngx-common/guards/sso-incomplete-user.guard';
import { AccountPagesRoutes } from '@ngx-common/pages/account-pages/model/account-routings.model';
import { ErrorPagesRedirectsGuard } from './guards/error-pages-redirects.guard';
import { GuestUserGuard } from './guards/guest-user.guard';
import { UserRequiredMfaGuard } from '@ngx-common/guards/user-required-mfa.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./main-layout/main-layout.module').then(mod => mod.MainLayoutModule),
		canActivate: [EnviromentsLoadGuard, GuestUserGuard],
	},
	{
		path: AccountPagesRoutes.LoginRedirect,
		loadChildren: () =>
			import('@ngx-common/pages/login-redirect/login-redirect.module').then(mod => mod.LoginRedirectModule),
		canActivate: [EnviromentsLoadGuard, SEOGuard],
	},
	{
		path: AccountPagesRoutes.Mfa,
		loadChildren: () => import('./main-layout/pages/security/security.module').then(mod => mod.SecurityModule),
		canActivate: [EnviromentsLoadGuard, UserRequiredMfaGuard, GuestUserGuard],
	},
	{
		path: AccountPagesRoutes.SSOAdditionalInformations,
		loadChildren: () =>
			import('@ngx-common/pages/sso-additional-info-page/sso-additional-info-page.module').then(
				mod => mod.SSOAdditionalInfoPageModule
			),
		canActivate: [EnviromentsLoadGuard, SSOIncompleteUserGuard, GuestUserGuard],
	},
	{
		path: AccountPagesRoutes.AdditionalBillingDetails,
		loadChildren: () =>
			import('@ngx-common/pages/billing-details-page/billing-details-page.module').then(
				mod => mod.BillingDetailsPageModule
			),
		canActivate: [EnviromentsLoadGuard, SSOIncompleteBillingDetailsUserGuard, GuestUserGuard],
	},
	{
		path: 'cookie-disabled',
		loadChildren: () =>
			import('@ngx-common/pages/cookie-disabled/cookie-disabled.module').then(mod => mod.CookieDisabledModule),
		canActivate: [EnviromentsLoadGuard, SEOGuard],
	},
	{
		path: `error/:code`,
		loadChildren: () => import('./error-page/error-page.module').then(mod => mod.AppErrorPageModule),
		canActivate: [SEOGuard],
	},
	{
		path: '**',
		pathMatch: 'full',
		loadChildren: () => import('./error-page/error-page.module').then(mod => mod.AppErrorPageModule),
		canActivate: [ErrorPagesRedirectsGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
