import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { of, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformServer } from '@angular/common';
import { ITranslation } from '../interfaces/interfaces.shared';

@Injectable({
	providedIn: 'root',
})
export class CLSTranslateService {
	constructor(@Inject(PLATFORM_ID) private platformId: object, private ngxTranslateSvc: TranslateService) {}

	get(transKey: string | string[], args?: object): Observable<any> {
		if (isPlatformServer(this.platformId)) {
			if (transKey instanceof Array) {
				const translates = {};
				for (const key of transKey) {
					translates[key] = key;
				}
				return of(translates);
			} else {
				return of(transKey);
			}
		}
		return this.ngxTranslateSvc.get(transKey, args);
	}

	async getAsync(transKey: string, args?: object): Promise<string>;
	async getAsync(transKey: string[], args?: object): Promise<ITranslation>;
	async getAsync(transKey: string | string[], args?: object): Promise<ITranslation | string> {
		return await this.get(transKey, args).toPromise();
	}
}
