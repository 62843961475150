import { EDeploymentType } from '@ngx-common/enums/deployment-type.enum';
import { IAppEnvironment } from './environment.interface';

export const environment: IAppEnvironment = {
	version: require('../../package.json').version,
	production: true,
	apiUrl: '',
	authUrl: 'https://auth.copyleaks.com',
	googleAnalyticsKey: 'UA-39719389-1',
	stripKey:
		'pk_live_51IFasMEbGD5vhCjObJNT63wCjt4bermE69va4ClYFXy0I5WuWNmCpkQovTylROZN5h2IMsCM3OvhY5ySO9OsJBPx00M0RRhHng',
	apiFrontEndUrl: 'https://api.copyleaks.com',
	adminWebsiteUrl: 'https://admin.copyleaks.com',
	mainWebsiteUrl: 'https://copyleaks.com',
	idUrl: 'https://id.copyleaks.com',
	deploymentType: EDeploymentType.Production,
	copyshieldAPI: 'https://copyshield.copyleaks.com',
	notificationsAPI: '',
	ltiUrl: 'https://lti.copyleaks.com',
	statusUrl: 'https://status.copyleaks.com',
	helpCenterLink: 'https://help.copyleaks.com',
	helpSupportLink: 'https://help.copyleaks.com',
	dashboardWebsiteUrl: 'https://app.copyleaks.com',
	captchaUrl: 'https://captcha.copyleaks.com',
	equalWebSiteKey: 'f2d29ca262743e835f1cd03800af4fee',
	equalWebIntegrity: 'sha512-73oZhkzO+7F1r8AXT5BtChHyVvx8GMuB3Pokx6jdnP5Lw7xyBUO4L5KKi7BwqovhoqOWjNmkah1iCiMniyt6Kw==',
	turnsTileSiteKey: '0x4AAAAAAADZUXiboAFN3tU8',
};
