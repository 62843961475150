import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSnackbarComponent } from './custom-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../pipes/shared/pipes.module';
@NgModule({
	declarations: [CustomSnackbarComponent],
	exports: [CustomSnackbarComponent],
	entryComponents: [CustomSnackbarComponent],
	imports: [CommonModule, MatIconModule, PipesModule],
})
export class CustomSnackbarModule {}
