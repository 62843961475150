export interface ILocale {
	code: string;
	label: string;
}

export interface ICountryRecommendation {
	name: string;
	languages: ILocale[];
}

export const countries: ICountryRecommendation[] = [
	{
		name: 'USA', // United States
		languages: [
			{
				code: 'en',
				label: 'English',
			},
			{
				code: 'es',
				label: 'Español',
			},
		],
	},
	{
		name: 'FRA', // France
		languages: [
			{
				code: 'fr',
				label: 'Français',
			},
		],
	},
	{
		name: 'PRT', // Portugal
		languages: [
			{
				code: 'pt',
				label: 'Português',
			},
		],
	},
	{
		name: 'BRA', // Brazil
		languages: [
			{
				code: 'pt',
				label: 'Português',
			},
		],
	},
	{
		name: 'IND', // Invdia
		languages: [
			{
				code: 'hi',
				label: 'हिन्दी',
			},
		],
	},
	{
		name: 'CHN', // China
		languages: [
			{
				code: 'zh',
				label: '汉语',
			},
		],
	},
	{
		name: 'BEL', // Belgium
		languages: [
			{
				code: 'fr',
				label: 'Français',
			},
			{
				code: 'en',
				label: 'English',
			},
		],
	},
	{
		name: 'ESP', // Spain
		languages: [
			{
				code: 'es',
				label: 'Español',
			},
		],
	},
	{
		name: 'GBR', // United Kingdom
		languages: [
			{
				code: 'en',
				label: 'English',
			},
		],
	},
	{
		name: 'MEX', // Mexico
		languages: [
			{
				code: 'es',
				label: 'Español',
			},
		],
	},
	{
		name: 'AUS', // Australia
		languages: [
			{
				code: 'en',
				label: 'English',
			},
		],
	},
	{
		name: 'CAN', // Canada
		languages: [
			{
				code: 'en',
				label: 'English',
			},
		],
	},
	{
		name: 'ITA', // Italy
		languages: [
			{
				code: 'it',
				label: 'Italiano',
			},
		],
	},
	{
		name: 'RUS', // Russia
		languages: [
			{
				code: 'ru',
				label: 'Русский',
			},
		],
	},
	{
		name: 'DEU', // Germany
		languages: [
			{
				code: 'de',
				label: 'Deutsch',
			},
		],
	},
	{
		name: 'TUR', // Turkey
		languages: [
			{
				code: 'tr',
				label: 'Türkçe',
			},
		],
	},
	{
		name: 'JAP', // Japan
		languages: [
			{
				code: 'ja',
				label: '日本語',
			},
		],
	},
];

export const SupportedLocales: ILocale[] = [
	{
		code: 'en',
		label: 'English',
	},
	{
		code: 'es',
		label: 'Español',
	},
	{
		code: 'fr',
		label: 'Français',
	},
	{
		code: 'pt',
		label: 'Português',
	},
	{
		code: 'hi',
		label: 'हिन्दी',
	},
	{
		code: 'zh',
		label: '汉语',
	},
	{
		code: 'it',
		label: 'Italiano',
	},
	{
		code: 'ja',
		label: '日本語',
	},
	{
		code: 'de',
		label: 'Deutsch',
	},
	{
		code: 'ru',
		label: 'Русский',
	},
	{
		code: 'tr',
		label: 'Türkçe',
	},
	{
		code: 'ar',
		label: 'العربية',
	},
];
