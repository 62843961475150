import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClsAlertsService } from '@ngx-common-v2/components/cls-alerts/services/cls-alerts.service';
import { tap } from 'rxjs/operators';
import { EAPIErrorCodes } from '../enums/error-codes.enum';
import { HttpStatusCode } from '../enums/http-status-code.enum';
import { AuthService } from '../services/auth.service';
import { OidcService } from '../services/oidc.service';
import { UrlLocationService } from '../services/url-location.service';

// interceptor
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
	excludeUrlsServiceUnavailable = [
		`/v1/scan/ai/embedded`,
		// `/${PlagiarismCheckerInlineWidgetRoute.MainPage}/${PlagiarismCheckerInlineWidgetRoute.AiEmbedded}`,
		'maintenance',
	];

	excludeEndpointForbiddenFromRedirect = ['RegisterAccessToReport', 'by-snapshot', 'get-readonly-access', 'share-list'];

	constructor(
		private _oidc: OidcService,
		private _urlLocaitonSvc: UrlLocationService,
		private _authSvc: AuthService,
		private _clsAlertsSvc: ClsAlertsService
	) {}
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return next.handle(req).pipe(
			tap(
				() => {},
				error => {
					if (error.status === HttpStatusCode.UNAUTHORIZED) {
						this._authSvc.clearUserData();
						if (this._shouldRedirectToLogin()) this._oidc.login();
					} else if (
						(error.status === HttpStatusCode.FORBIDDEN && error?.error?.code === EAPIErrorCodes.BillingBlock) ||
						error?.message?.code === EAPIErrorCodes.BillingBlock
					) {
						const newLocal = `Your account has been locked for payments.<br/>
					Please <a href="${environment.helpCenterLink}/kb-tickets/new">Contact us</a> to unlock your Copyleaks account.`;
						this._clsAlertsSvc.showCustomError(newLocal, 18000);
					} else if (error.status === HttpStatusCode.FORBIDDEN && this._shouldRedirectForbidden()) {
						this._clsAlertsSvc.showCustomError($localize`Can't access this resource`);
						if (!this.excludeEndpointForbiddenFromRedirect.some(ex => error.url.includes(ex)))
							this._urlLocaitonSvc.location.href = '/';
					}
					if (error.status === 503 && !this.excludeUrlsServiceUnavailable.includes(location?.pathname)) {
						if (error.url && error.url.includes('notifications')) return;
						location.href = '/maintenance';
					}
				}
			)
		);
	}

	private _shouldRedirectToLogin(): boolean {
		var ignoredUrls = [
			'/v1/scan',
			'/v1/scan/',
			'/v1/scan/embedded',
			'/v1/scan/embedded/',
			'/v1/scan/ai/embedded',
			'/v1/scan/ai/embedded/',
		];
		var canLogin = !ignoredUrls.includes(this._urlLocaitonSvc.location.pathname);
		return canLogin;
	}

	private _shouldRedirectForbidden(): boolean {
		var ignoredUrls = ['/v1/scan/ai/embedded', '/v1/scan/ai/embedded/'];
		var canRedirect = !ignoredUrls.includes(this._urlLocaitonSvc.location.pathname);
		return canRedirect;
	}
}
