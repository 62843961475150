import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class DevicesService {
	constructor(@Inject(PLATFORM_ID) private platformId: object) {}
	isIOS() {
		if (isPlatformBrowser(this.platformId)) {
			return /(iPad|iPhone|iPod)/g.test(navigator.userAgent) && !(window as any).MSStream;
		} else {
			return false;
		}
	}
	isSafari() {
		if (isPlatformBrowser(this.platformId)) {
			const ua = navigator.userAgent.toLowerCase();
			if (ua.indexOf('safari') !== -1) {
				if (ua.indexOf('chrome') > -1) {
					return false;
				} else {
					return true;
				}
			}
		} else {
			return false;
		}
	}
	isApple() {
		return this.isIOS() || this.isSafari();
	}

	isInternetExplorer() {
		if (isPlatformBrowser(this.platformId)) {
			return /*@cc_on!@*/ false || !!(document as any).documentMode;
		} else {
			return false;
		}
	}
}
