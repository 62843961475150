import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { ORG_ROLES } from '../constants/organization-roles.consts';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { ELocalStorageKeys } from '../enums/local-storage-keys.enum';
import { EProductType } from '../enums/product-type.enums';
import { ESessionStorageKeys } from '../enums/session-storage-keys.enum';
import { EUserKind } from '../enums/user-kinds.enum';
import { SupportedLocales } from '../i18n/locales.value';
import { UserInfo } from '../models/auth.model';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '../pages/pages.config';
import { AccountPagesRoutes } from './../pages/account-pages/model/account-routings.model';
import { DataSharingService } from './data-sharing.service';
import { LocalStorageService } from './localstorage.service';
import { ESSOProviders, OidcService } from './oidc.service';
import { SessionStorageService } from './session-storage.service';
import { UrlLocationService } from './url-location.service';
import { ERegisterationSource } from '../enums/registeration-source.enum';
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private _isLogedIn$ = new BehaviorSubject<boolean>(null);
	private _user$ = new BehaviorSubject<UserInfo>(null);
	private _SSOIncompleteUser$ = new BehaviorSubject<UserInfo>(null);
	private _IncompleteBillingDetailsUser$ = new BehaviorSubject<UserInfo>(null);
	private _IncompleteOrganizationUserInfo$ = new BehaviorSubject<UserInfo>(null);
	private _IncompleteSecurityInfo$ = new BehaviorSubject<UserInfo>(null);
	intervalSubscription: Subscription;

	private _onLoginSuccess$ = new Subject<boolean>();
	public get onLoginSuccess$(): Observable<any> {
		return this._onLoginSuccess$.asObservable();
	}

	public get isLogedIn$() {
		return this._isLogedIn$;
	}

	public get user$() {
		return this._user$;
	}

	public get SSOIncompleteUser$() {
		return this._SSOIncompleteUser$;
	}
	public get IncompleteBillingDetailsUser$() {
		return this._IncompleteBillingDetailsUser$;
	}
	public get IncompleteOrganizationUserInfo$() {
		return this._IncompleteOrganizationUserInfo$;
	}
	public get IncompleteSecurityInfo$() {
		return this._IncompleteSecurityInfo$;
	}

	public get token() {
		if (isPlatformBrowser(this._platformId)) {
			const token = this._oidcSvc.getToken();
			return token;
		}
		return '';
	}

	public get isGuestUser() {
		return this._user$?.value?.userKind == EUserKind.GuestAccount;
	}

	constructor(
		private _http: HttpClient,
		private _dataSharingSvc: DataSharingService,
		private _localStorageSvc: LocalStorageService,
		private _sessionStorageSvc: SessionStorageService,
		private _urlLocationSvc: UrlLocationService,
		private _oidcSvc: OidcService,
		private _router: Router,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig,
		@Inject(PLATFORM_ID) private _platformId: string
	) {
		this.isLoggedIn();
		this._initUserStateInterval();
	}

	private _initUserStateInterval() {
		// this cron job run every 30 seconds
		if (isPlatformBrowser(this._platformId) && environment.production) {
			this.intervalSubscription = interval(180000).subscribe(_ => {
				this._checkUserStateIntervalAsync();
			});
		}
	}

	private async _checkUserStateIntervalAsync() {
		if (this._urlLocationSvc.location.pathname == `/${AccountPagesRoutes.LoginRedirect}`) return;
		await this.checkLoginAsync();
	}

	async checkIfUserAlreadyLoggedin() {
		const token = this._oidcSvc.getToken();
		let user: string = this.getUserFromLocalStorage();
		if (!user) {
			user = this._sessionStorageSvc.getItem(ESessionStorageKeys.User);
		}
		if (token && !user && this._urlLocationSvc.location.pathname !== `/${AccountPagesRoutes.LoginRedirect}`) {
			this._oidcSvc.getUserInfoLogin();
			return false;
		}
		if (!token || !user) {
			return false;
		}
		this.setUserData(JSON.parse(user));
		return true;
	}

	async onLoginSuccessAsync(): Promise<UserInfo> {
		var userInfo = await this._http.post<UserInfo>(`${environment.apiUrl}/v1/account/login/success`, {}).toPromise();
		this._onLoginSuccess$.next(true);
		return userInfo;
	}

	login(redirectToCurrentPath = true) {
		this._oidcSvc.login(redirectToCurrentPath);
	}

	loginWithReturnUrl(returnUrl: string) {
		this._oidcSvc.loginWithReturnUrl(returnUrl);
	}

	socialLogin(ssoProvider: ESSOProviders, redirectUrl?: string, regSource: ERegisterationSource = null) {
		this._oidcSvc.socialLogin(ssoProvider, redirectUrl, regSource);
	}

	async getUserInfoAsync() {
		try {
			const userData = await this._http.get<UserInfo>(`${environment.apiUrl}/v1/account/info`).toPromise();
			return await this.updateUserData(userData);
		} catch (error) {
			throw error;
		}
	}

	canUserAccessBilling() {
		if (this._user$.value.isPartOfOrganization) {
			if (this.checkUserIfOrganizationOwner()) {
				return true;
			}
			return false;
		}
		return true;
	}

	async checkLoginAsync() {
		try {
			if (!this.isLoggedIn()) return;
			await this._http.get<UserInfo>(`${environment.apiUrl}/v1/account/login/check`).toPromise();
		} catch (error) {
			console.log(error);
		}
	}

	public async updateUserData(userData: UserInfo, needToUpdateSubscription = true) {
		if (this._config.APP !== ECopyleaksAPP.LMS) {
			userData.countryCode = userData.countryCode ? userData.countryCode : 'OTR';
		}
		this.setUserData(userData, needToUpdateSubscription);
		return userData;
	}

	public async updateTempUserData(userData: UserInfo) {
		this.SSOIncompleteUser$.next(userData);
	}

	public async updateTempBillingDetails(userData: UserInfo) {
		this.IncompleteBillingDetailsUser$.next(userData);
	}

	public async updateTempOrganizationBillingDetails(userData: UserInfo) {
		this.IncompleteOrganizationUserInfo$.next(userData);
	}

	public async updateIncompleteUserSecurityData(userData: UserInfo) {
		this.IncompleteSecurityInfo$.next(userData);
	}

	async logoutAsync() {
		if (isPlatformBrowser(this._platformId)) {
			this._oidcSvc.logout();
		}
	}

	setUserData(user: UserInfo, needToUpdateSubscription = true) {
		if (!user) return;
		this._localStorageSvc.setItem(ELocalStorageKeys.User, JSON.stringify(user));
		if (needToUpdateSubscription) {
			this._user$.next(user);
			this._isLogedIn$.next(true);
		}
	}

	async clearUserData() {
		this._localStorageSvc.clear();
		this._sessionStorageSvc.removeItem(ESessionStorageKeys.User);
		this._dataSharingSvc.clear();
		this._isLogedIn$.next(false);
		this._user$.next(null);
	}

	isLoggedIn() {
		if (this._isLogedIn$.value) return true;
		const user = this.getUserFromLocalStorage();
		const token = this._oidcSvc.getToken();
		if (!token && user) {
			return false;
		}
		if (!token || !user) {
			return false;
		}
		if (!this._oidcSvc.isLoggedIn()) return false;
		return true;
	}

	async isLoggedInAsync() {
		if (this._isLogedIn$.value) return true;
		const user = this.getUserFromLocalStorage();
		this.setUserData(JSON.parse(user));
		const token = await this._oidcSvc.getTokenAsync();
		if (!token && user) {
			return false;
		}
		if (!token || !user) {
			return false;
		}
		if (!this._oidcSvc.isLoggedIn()) return false;
		return true;
	}

	checkIfUserHaveRoles(requiredRoles: string[]) {
		if (!requiredRoles || !requiredRoles.length) return true;
		return this.user$?.value?.roles?.find(i => requiredRoles.find(r => r == i)) != null;
	}

	checkUserIfOrganizationAdmin() {
		return this.checkIfUserHaveRoles([ORG_ROLES.Admin]);
	}

	checkUserIfOrganizationOwner() {
		return this.checkIfUserHaveRoles([ORG_ROLES.Owner]);
	}

	checkUserIfOrganizationMember() {
		return this.checkIfUserHaveRoles([ORG_ROLES.Member]);
	}
	checkIfIsPartOfOrganization() {
		return this.checkIfUserHaveRoles([ORG_ROLES.Admin, ORG_ROLES.Owner, ORG_ROLES.Member]);
	}

	getUserFromLocalStorage() {
		return this._localStorageSvc.getItem(ELocalStorageKeys.User);
	}

	user(): UserInfo {
		return this.user$.value;
	}

	isStudentProfile(): boolean {
		const profile = this.user()?.profileType;
		return profile === 4 || profile === 7 || profile === 11 || profile === 12 || profile === 13;
	}

	isBusinessProfile(): boolean {
		const profile = this.user()?.teamAdminProfileType ?? this.user()?.profileType;
		return (
			profile === 14 ||
			profile === 15 ||
			profile === 16 ||
			profile === 17 ||
			profile === 18 ||
			profile === 19 ||
			profile === 20 ||
			profile === 22
		);
	}

	isTeachersProfile(): boolean {
		const profile = this.user()?.profileType;
		return (
			profile === 1 ||
			profile === 2 ||
			profile === 3 ||
			profile === 5 ||
			profile === 6 ||
			profile === 8 ||
			profile === 9 ||
			profile === 10 ||
			profile === 20 ||
			profile === 101
		);
	}

	public async afterLoginRedirect(userInfo: UserInfo) {
		if (isPlatformBrowser(this._platformId)) {
			await this.updateUserData(userInfo);
			const redirectUrl = decodeURIComponent(this.getRedirectFromUrl() || this._oidcSvc.getRedirectUrl());
			if (!!redirectUrl) {
				let hasLanguage = false;
				if (
					this._config.APP == ECopyleaksAPP.MainWebsite &&
					redirectUrl.startsWith('/browser-extension/login/successful')
				) {
					this._urlLocationSvc.location.href = `${environment.mainWebsiteUrl}/ai-detector-extension-login-successful`;
					return;
				}
				if (
					this._config.APP == ECopyleaksAPP.MainWebsite &&
					redirectUrl.startsWith('/browser-extension/grc/login/successful')
				) {
					this._urlLocationSvc.location.href = `${environment.mainWebsiteUrl}/grc-successful-login`;
					return;
				}
				if (this._config.APP == ECopyleaksAPP.MainWebsite && redirectUrl.startsWith('ai-content-detector')) {
					this._urlLocationSvc.location.href = `${environment.mainWebsiteUrl}/ai-content-detector`;
					return;
				}
				for (const l of SupportedLocales) {
					if (redirectUrl.startsWith(`/${l.code}/`)) {
						hasLanguage = true;
						break;
					}
				}
				if (hasLanguage) {
					setTimeout(() => {
						this._urlLocationSvc.location.href = redirectUrl;
					}, 100);
				} else {
					setTimeout(() => {
						this._router.navigateByUrl(redirectUrl);
					}, 100);
				}
			} else {
				this._router.navigate(['/']);
			}
		}
	}

	getRedirectFromUrl() {
		const split = this._urlLocationSvc.location.search.split('redirect=');
		if (split.length == 2 && this._config.APP == ECopyleaksAPP.MainWebsite) {
			return decodeURIComponent(split[1]);
		}
		return '';
	}

	getCurrentUserProductType() {
		if (this._config.APP == ECopyleaksAPP.API || this._config.APP == ECopyleaksAPP.LMS) return EProductType.Api;
		else if (this._config.APP == ECopyleaksAPP.MainWebsite) return EProductType.App;
		return EProductType.App;
	}

	public isSSOUser(): boolean {
		return this.user$?.value?.userKind == EUserKind.SSOAccount;
	}

	public checkIfUserIsSSO(user: UserInfo): boolean {
		return user.userKind == EUserKind.SSOAccount;
	}
}
