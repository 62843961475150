import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';
import { EDeploymentType } from '../enums/deployment-type.enum';
import { ECopyleaksAPP, NgxCommonPagesConfig } from '@ngx-common/pages/pages.config';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	private _isEnviromentsLoaded$ = new BehaviorSubject<boolean>(false);
	lastDomain = '.com';
	get isEnviromentsLoaded$() {
		return this._isEnviromentsLoaded$;
	}

	get isEuUser(): boolean {
		var originURL = location?.origin;
		return this.isEuSite(originURL);
	}

	constructor(
		private _http: HttpClient,
		@Inject(PLATFORM_ID) private _platformId,
		@Inject(NgxCommonPagesConfig.key) private _config: NgxCommonPagesConfig
	) {}

	getLastDomain() {
		return this.lastDomain;
	}

	isEuSite(originURL: string): boolean {
		return (
			originURL.endsWith('.eu') || (this._config.APP == ECopyleaksAPP.AgentsDashboard && originURL.includes('.16.'))
		);
	}

	async initEnvironementVariables() {
		if (isPlatformBrowser(this._platformId)) {
			if (this._isEnviromentsLoaded$.value) {
				return;
			}
			var originURL = location?.origin;
			var STAGE = 'stage';
			var isStage = originURL.includes(STAGE);
			if (
				(environment.deploymentType === EDeploymentType.Beta || environment.deploymentType == EDeploymentType.LOCAL) &&
				!isStage
			) {
				environment.stripKey =
					'pk_test_51KjHpnJcVTiazDppzGsC3jItlIDJpQkfT0ZlsOX3xf0mIAR7TeZBEIictM8anh3NdZ1tWOAmZzGbZvbc4CNAzdL200fS07w9PN';
				var serverEnv = await this._http.get<Sites>(`${environment.apiUrl}/v1/enviroments`).toPromise();
				environment.copyshieldAPI = serverEnv.copyshieldAPI?.slice(0, -1);
				environment.authUrl = serverEnv.authUrl?.slice(0, -1);
				environment.idUrl = serverEnv.idUrl?.slice(0, -1);
				environment.apiFrontEndUrl = serverEnv.apiFrontEndUrl?.slice(0, -1);
				environment.dashboardWebsiteUrl = serverEnv.dashboardWebsiteUrl?.slice(0, -1);
				environment.adminWebsiteUrl = serverEnv.adminWebsiteUrl?.slice(0, -1);
				environment.mainWebsiteUrl = serverEnv.mainWebsiteUrl?.slice(0, -1);
			} else if (
				environment.deploymentType !== EDeploymentType.LOCAL &&
				environment.deploymentType !== EDeploymentType.LOCAL_NGINX
			) {
				const EU = '.eu';
				if (isStage) {
					if (this.isEuSite(originURL))
						environment.stripKey =
							'pk_test_51KiCnSEeIOKOlPCdRLZE09KkaBKaQJ9AsHXt5xxsPEtAgQaqNjOKODQsItSvoqxy4Bt1kRay9QG3QZDhLs79NhL400ouC9PUAH';
					else
						environment.stripKey =
							'pk_test_51IFasMEbGD5vhCjOYYVz4mUAWsnP7mtvDN56apM57zOkyh0RUrB6Vioymc467jNjyTYyGpEN8pPADgNjsSR20lo4006htvRjN4';
					environment.copyshieldAPI = `https://copyshield-${STAGE}.copyleaks.com`;
					environment.authUrl = `https://auth-${STAGE}.copyleaks.com`;
					environment.idUrl = `https://id-${STAGE}.copyleaks.com`;
					environment.apiFrontEndUrl = `https://api-${STAGE}.copyleaks.com`;
					environment.dashboardWebsiteUrl = `https://app-${STAGE}.copyleaks.com`;
					environment.captchaUrl = `https://captcha-${STAGE}.copyleaks.com`;
					environment.ltiUrl = `https://lti-${STAGE}.copyleaks.com`;
					environment.adminWebsiteUrl = `https://admin-${STAGE}.copyleaks.com`;
					environment.mainWebsiteUrl = `https://staging.copyleaks.com`;
				} else {
					if (this.isEuSite(originURL))
						environment.stripKey =
							'pk_live_51KiCnSEeIOKOlPCdTMMumTwtl7Y578b1IOJf9Lpl8VFqPVtKqfdOQWPRZtg6MyiLZW1HLqTstiV24d21tYVqHNhm00Rz5MIhV2';
					else
						environment.stripKey =
							'pk_live_51IFasMEbGD5vhCjObJNT63wCjt4bermE69va4ClYFXy0I5WuWNmCpkQovTylROZN5h2IMsCM3OvhY5ySO9OsJBPx00M0RRhHng';
				}
				var COM = '.com';
				if (this.isEuSite(originURL)) {
					environment.equalWebIntegrity =
						'sha512-73oZhkzO+7F1r8AXT5BtChHyVvx8GMuB3Pokx6jdnP5Lw7xyBUO4L5KKi7BwqovhoqOWjNmkah1iCiMniyt6Kw==';
					environment.equalWebSiteKey = '830d066d9494de8070c4868f316da6b0';
					environment.copyshieldAPI = environment.copyshieldAPI.replace(COM, EU);
					environment.authUrl = environment.authUrl.replace(COM, EU);
					environment.idUrl = environment.idUrl.replace(COM, EU);
					environment.apiFrontEndUrl = environment.apiFrontEndUrl.replace(COM, EU);
					environment.ltiUrl = environment.ltiUrl.replace(COM, EU);
					environment.statusUrl = environment.statusUrl.replace(COM, EU);
					environment.helpCenterLink = environment.helpCenterLink.replace(COM, EU);
					environment.helpSupportLink = environment.helpSupportLink.replace(COM, EU);
					environment.dashboardWebsiteUrl = environment.dashboardWebsiteUrl.replace(COM, EU);
					environment.adminWebsiteUrl = environment.adminWebsiteUrl.replace(COM, EU);
					environment.captchaUrl = environment.captchaUrl.replace(COM, EU);
					this.lastDomain = EU;
				}
			}
			this._isEnviromentsLoaded$.next(true);
		}
	}

	getDomain() {
		if (
			environment.deploymentType === EDeploymentType.LOCAL ||
			environment.deploymentType === EDeploymentType.LOCAL_NGINX
		) {
			return '';
		}
		return `.${environment.authUrl.split('.')[1]}.${environment.authUrl.split('.')[2]}`;
	}

	_getCookieDomain(): string {
		let domain = this.getDomain();
		if (domain[domain.length - 1] == '/') return domain.split('/')[0];
		else return domain;
	}
}

export interface Sites {
	idUrl: string;
	authUrl: string;
	apiFrontEndUrl: string;
	copyshieldAPI: string;
	mainWebsiteUrl: string;
	dashboardWebsiteUrl: string;
	adminWebsiteUrl: string;
}
