// tslint:disable
export class AccountPagesRoutes {
	static LoginRedirect = 'login-redirect';
	static SSOAdditionalInformations = 'additional-informations';
	static AdditionalBillingDetails = 'additional-billing-details';
	static OrganizationDetails = 'organization-details';
	static OrganizationAddSeats = 'organization-add-seats';
	static AddSeats = 'add-seats';
	static UnderMaintenance = 'maintenance';
	static Mfa = 'mfa';
}
