<mat-progress-bar
	*ngIf="pageLoading"
	mode="indeterminate"
	class="navigation-progress-bar"
	value="40"></mat-progress-bar>

<router-outlet></router-outlet>

<!-- flash alerts -->
<ng-container *ngIf="!pageLoading" #flashAlertsContainer></ng-container>
