import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { HttpStatusCode } from '../enums/http-status-code.enum';

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_RETRY_DELAY = 2000; // 2 secounds

export function httpFailRetry(delayMS = DEFAULT_RETRY_DELAY, maxRetry = DEFAULT_MAX_RETRIES) {
	let retries = maxRetry;
	return (src: Observable<any>) =>
		src.pipe(
			retryWhen((errors: Observable<any>) =>
				errors.pipe(
					delay(delayMS),
					mergeMap(error => {
						if (
							(error && error.status === 0) ||
							(error && error.status === HttpStatusCode.SERVICE_UNAVAILABLE) ||
							(error && error.status === HttpStatusCode.FORBIDDEN) ||
							(error && error.status === HttpStatusCode.UNAUTHORIZED) ||
							(error && error.status === HttpStatusCode.TOO_MANY_REQUESTS) ||
							(error.error && (error.error.code || error.error.Code)) ||
							(error.error && (error.error.description || error.error.Description)) ||
							--retries === 0
						) {
							return throwError(error);
						}
						return of(error);
					})
				)
			)
		);
}

export function httpPostFailRetry(delayMS = DEFAULT_RETRY_DELAY, maxRetry = DEFAULT_MAX_RETRIES) {
	let retries = maxRetry;
	return (src: Observable<any>) =>
		src.pipe(
			retryWhen((errors: Observable<any>) =>
				errors.pipe(
					delay(delayMS),
					mergeMap(error => {
						if (
							(error && error.status === 0) ||
							(error && error.status === HttpStatusCode.SERVICE_UNAVAILABLE) ||
							(error && error.status === HttpStatusCode.FORBIDDEN) ||
							(error && error.status === HttpStatusCode.UNAUTHORIZED) ||
							--retries === 0
						) {
							return throwError(error);
						}
						return of(error);
					})
				)
			)
		);
}
