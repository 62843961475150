export class MainLayoutRouting {
	public static MAIN = '';
	public static PERSONAL_INFORMATION = 'personal-information';
	public static SECURITY = 'security';
	public static AUDITS_LOG = `${this.SECURITY}/audit-logs`;
	public static SECURITY_DELETE_INTERNAL_DATA = `${this.SECURITY}/delete-internal-data`;
	public static TEAMS = 'teams';
	public static ORGANIZATION_INVITATION = 'organization/invitation';
	public static EXPORT_USAGE = 'export-usage';
	public static REFERRAL_PROGRAM = 'referral-program';
	public static REPOSITORIES = 'repositories';
	public static BILLING = 'billing';
	public static BillingsPaymentsHistoryPage = 'billing/payments-history';
	public static BILLING_UPDATE_PAYMENT_METHOD = 'billing/update-payment-method';
}
