import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class SessionStorageService {
	constructor(@Inject(PLATFORM_ID) private platformId: object) {}

	setItem(key: string, value: any) {
		if (isPlatformBrowser(this.platformId)) {
			sessionStorage.setItem(key, value);
		}
	}

	getItem(key: string) {
		if (isPlatformBrowser(this.platformId)) {
			return sessionStorage.getItem(key);
		}
		return null;
	}

	removeItem(key: string) {
		if (isPlatformBrowser(this.platformId)) {
			return sessionStorage.removeItem(key);
		}
		return null;
	}
}
